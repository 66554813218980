<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/xml">{{nameReport}}</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- FILTER -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <b style="font-size: 1rem;">{{data.storeName}}</b>
                        </div>
                        <div class="col-md-4">
                            <label>Del:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="filter.begDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Para:</label>
                            <div class="form-group">
                                <input type="date" class="form-control" [(ngModel)]="filter.endDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <br>
                            <div class="form-group">
                                <button class="btn btn-info font-weight-bold w-100 btn-erp" [disabled]="disabled"
                                    (click)="getAll()">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END FILTER -->


    <!-- TABLA -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de resultados</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Buscar..."</h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success font-weight-bold w-100 btn-erp" (click)="downloadToExcel()"
                                [disabled]="disabled || rows.length <= 0">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Ver
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">Todas</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Buscar..." aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="rows" [scrollbarH]="true" [colWidth]="'auto'" (activate)="onActivate($event)"
                            *ngIf="typeReport == 'SALE'">
                            <ngx-datatable-column name="ID SERIE" prop="serie"></ngx-datatable-column>
                            <ngx-datatable-column name="NO DOCUMENTO" prop="numero"></ngx-datatable-column>
                            <ngx-datatable-column name="MONEDA" prop="moneda"></ngx-datatable-column>
                            <ngx-datatable-column name="NIT CONTRIBUYENTE" prop="nit_Cliente"></ngx-datatable-column>
                            <ngx-datatable-column name="NOMBRE CONTRIBUYENTE" prop="nombre_Cliente"></ngx-datatable-column>
                            <ngx-datatable-column name="FECHA EMISION" prop="fecha_Emision"></ngx-datatable-column>
                            <ngx-datatable-column name="IVA" prop="iva"></ngx-datatable-column>
                            <ngx-datatable-column name="TOTAL" prop="total"></ngx-datatable-column> 
                        </ngx-datatable>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [colWidth]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="rows" [scrollbarH]="true"  (activate)="onActivate($event)"
                            *ngIf="typeReport == 'BOOK'">
                            <ngx-datatable-column name="DOCUMENTO" prop="documento"></ngx-datatable-column>
                            <ngx-datatable-column name="SERIE DEL DOCUMENTO" prop="serieDocumento"></ngx-datatable-column>
                            <ngx-datatable-column name="NUMERO DEL DOCUMENTO" prop="numeroDocumento"></ngx-datatable-column>
                            <ngx-datatable-column name="FECHA DEL DOCUMENTO" prop="fechaDocumento"></ngx-datatable-column>
                            <ngx-datatable-column name="NIT DEL CLIENTE" prop="nitCliente"></ngx-datatable-column>
                            <ngx-datatable-column name="NOMBRE DEL CLIENTE" prop="nombreCliente"></ngx-datatable-column>
                            <ngx-datatable-column name="IVA" prop="iva"></ngx-datatable-column>
                            <ngx-datatable-column name="TOTAL" prop="total"></ngx-datatable-column> 
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLA -->
</div>