export const environment = {
  production: false,

  url: "https://demo.clever.com.gt/",
  //apiUrl: "https://dn3quh23w0.execute-api.us-east-1.amazonaws.com/demos/",
  apiUrl: "https://rameu7uck1.execute-api.us-east-1.amazonaws.com/demorest/",
  userPoolId: "us-east-1_AqgtMCsFH",
  clientId: "6lhcelf314ue77okri701h7sb6",
  apiIMFEL: "https://hxu7fhirl5.execute-api.us-east-1.amazonaws.com/demo/imfelserviceserverless-client/taxDocument/",
};

export const cognitoCodes: { [key: string]: string } = {
  "yeimiuriarte@gmail.com":"b92f1f97-9829-4c87-8302-0b43b87f2ed3",
  "estacionyserviciosjerusalen20@gmail.com":"a8ec4220-328e-4379-9c67-d3cad01b99cd",
  "abc30757341@gmail.com":"79e0386b-6cba-4018-b08d-cf0213a153e8",
  "agreda038@gmail.com":"dc3150e5-c524-4b7f-b385-10aec61daee9",
  "nicolasyorbic@gmail.com":"28bf326f-6384-422f-8049-b7cec073abd7",
  "superturno1@gmail.com":"083a4b75-1475-4281-9276-f1805e4dc0db",
  "superturno2@gmail.com":"afc913b3-b0fc-4775-9449-ad3532f258aa",
  "robindanielp1705@gmail.com":"7baeb244-197b-4f33-bd58-35ec3248f99b",
  "demo+sylvania@gmail.com":"87f6e521-7e0c-48b0-9ae2-5829ccbfdfc6",
  "jmazariegos@gmail.com":"3e147cf4-7c54-43f0-bee1-aefdeb496496",
  "jmazariegos2@gmail.com":"c0dba23c-771d-4360-9269-87e9ec6da873",
  "pruebapena@gmail.com": "xXh3WNtundj1qMptjREvcw1ANRvVRatx",
  "jmazariegos+sylvania@gmail.com": "87f6e521-7e0c-48b0-9ae2-5829ccbfdf11",
};