<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/company-type">Comandas</a>
        </div>
    </div>
</div>

<div class="main-content">

    <!-- INPUT COMPANY -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <form #formulario="ngForm">
                <div class="card cardprincipal">
                    <div class="card-header ">
                        <div class="row">
                            <div class="col-10">
                                <h3 class="card-title">Ordenes Pendientes</h3>
                                <h6>Ultima Actualización: {{dateUpdateOrders}}</h6>
                                <button class="btn btn-warning btn-sale text-center" style="width: 15%;" (click)="getAllOrdersPending()">Actualizar Ordenes</button>
                            </div>                            
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                                                        
                            <div class="products-card" *ngFor="let o of OrderList">                                
                                <div class="card card-product text-center cardproduct">
                                    <div class="card-body cardbodyproduct">
                                        <h6>Mesa {{o.restaurantOrdersTableCode}} - Orden {{o.restaurantOrdersCode}}</h6>
                                        <h5 class="card-title" *ngFor="let p of o.restaurantOrdersDetail">
                                            {{p.quantity}} {{p.description}}
                                        </h5>
                                    </div>
                                    <div class="card-footer mt-auto">
                                        <button class="btn btn-sale btn-warning" (click)="closeCommand(o.restaurantOrdersCode)">Lista</button>
                                    </div>                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- END INPUT COMPANY -->    
</div>