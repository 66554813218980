import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from '../../services/user.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { StoreService } from 'src/app/services/store.service';
import swal from "sweetalert2";
import { resourceLimits } from 'worker_threads';
import { error } from 'console';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from "src/app/models/company";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [UtilsService, InventoryService, StoreService, CompanyService]
})
export class HomeComponent implements OnInit {
  public user: any;
  public storeCode: number;
  public storeName: number;
  public companyCode: number;
  public companyName: string;
  public identify: string;
  public nowDay: string;
  public begDate: string;
  public endDate: string;
  public Top: string;
  public temp = [];
  public inventoryList: any = [];
  public entries: number = 10;
  public activeRow: any;
  public isViewBeginning: boolean;
  public isStoreOpen: boolean;
  loading;
  public company = {} as Company;
  public OpenCash: boolean;

  // SELECT
  public storeList: any[] = new Array();
  public selectedItemsStore = [];
  public dropdownSettingsStore = {};
  public companyList: any[] = new Array();
  public selectedItemsCompany = [];
  public dropdownSettingsCompany = {};

  constructor(
    private _userService: UserService,
    private _utilService: UtilsService,
    private _inventoryService: InventoryService,
    private _storeService: StoreService,
    private cdref: ChangeDetectorRef,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;    

    // GET DATA LOCAL-STORAGE  
    if (localStorage.getItem('data')) {
      const _data = JSON.parse(localStorage.getItem('data'));
      this.storeName = _data.storeName ? _data.storeName.toString() : 0;
      this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
      this.companyName = _data.companyName ? _data.companyName.toString() : 0;
      this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
      this.isStoreOpen = _data.isStoreOpen ? (_data.isStoreOpen.toString().toLowerCase() == "true" ? this.isStoreOpen = true : false) : false;
      await this.getOneUserCompanyStore(_data.userCode, _data.companyCode, _data.storeCode);
      console.log('data',_data);
    } else {
      const _user = JSON.parse(localStorage.getItem('user'));
      await this.getOneUser(_user.userEmail);
    }

    await this.getCompany(this.companyCode);
    this.OpenCash = this.company.companyOpenCash == 1 ? this.OpenCash = true : this.OpenCash = false;
    console.log('OpenCash -> ', this.OpenCash);
    console.log('isStoreOpen -> ', this.isStoreOpen);

    this.nowDay = this._utilService.dateTime('yyy-MM-dd');
    this.begDate = this.nowDay;
    this.endDate = this.nowDay;
    // this.begDate = "2023-03-01";
    // this.endDate = "2023-06-30";
    this.Top = "10";
    this.isViewBeginning = false;

    this.getTopSalesIni(this.storeCode, this.begDate, this.endDate, this.Top);    

    this.loading = false;
  }  

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];                    
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.inventoryList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  viewFunction(event){

  }

  getTopSalesIni(storeCode,begDate,endDate,Top) {
    this.loading = true;
    return this._inventoryService.getByStoreTopSales(storeCode, begDate,endDate,Top).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
          this.temp = this.inventoryList;
          console.log(this.temp);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getTopSales() {
    console.log('top ventas');
    this.loading = true;
    return this._inventoryService.getByStoreTopSales(this.storeCode, this.begDate, this.endDate, this.Top).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
          this.temp = this.inventoryList;
          console.log(this.temp);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  ///////////////////////FUNCTIONS///////////////////////
  // GET USER BY EMAIL
  getOneUser(userEmial) {
    this.loading = true;

    return this._userService.oneUserEmailBackend(userEmial).then(
      response => {
        if (response.result) {
          this.user = response.records[0];
          this.storeName = this.user.storeName,
          this.storeCode = this.user.storeCode,
          this.companyName = this.user.companyName;
          this.companyCode = this.user.companyCode,
          this.identify = this.user.userPassword;
          this.getOneUserCompanyStore(this.user.userCode, this.user.companyCode, this.user.storeCode)

          const data = {
            "userCode": this.user.userCode,
            "storeName": this.user.storeName,
            "storeCode": this.user.storeCode,
            "companyCode": this.user.companyCode,
            "companyName": this.user.companyName,
            "identify": this.user.userPassword,
          };
          localStorage.setItem('data', JSON.stringify(data));
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getOneUserCompanyStore(userCode, companyCode, storeCode) {
    return this._userService.getOneUserCompanyStore(userCode).then(
      async response => {
        if (response.result) {
          this.companyList = response.records;
          if (companyCode > 0 && storeCode > 0) {
            let selectedItemsCompany = await this.companyList.find(x => x.id == companyCode);
            this.storeList = selectedItemsCompany.stores;
            let selectedItemsStore = await this.storeList.find(x => x.id == storeCode);
            this.getSelectSettings(selectedItemsCompany, selectedItemsStore);
          }
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // CHANGE STORE USER
  changeStore($event) {
    $event.preventDefault();
    if (this.companyCode > 0 && this.storeCode > 0) {
      this.loading = true;

      //console.log('ingresa a cambiar sucursal');

      const _data = JSON.parse(localStorage.getItem('data'));
      //console.log('data log: ', _data);
      const data = {
        "userCode": _data.userCode,
        "storeCode": this.storeCode,
        "storeName": this.storeName,
        "companyCode": this.companyCode,
        "companyName": this.companyName,        
        "identify": _data.identify,
        "ip": _data.ip,
      };
      localStorage.setItem('data', JSON.stringify(data));
      console.log('data new: ', JSON.parse(localStorage.getItem('data')));

      setTimeout(() => {
        this._utilService.showNotification(1, 'Cambio Realizado');
        setTimeout(() => {
          //window.location.reload();
          this.loading = false;
        }, 500)
      }, 1500)
    } else {
      this._utilService.showNotification(2, 'Selecciona una empresa y sucursal');
    }
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////
  async getSelectSettings(company, store) {
    await Object.keys(company).length > 0 ? this.selectedItemsCompany = [company] : '';
    await Object.keys(store).length > 0 ? this.selectedItemsStore = [store] : '';

    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      lazyLoading: true,
    };

    this.dropdownSettingsStore = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      lazyLoading: true,
    };
  };

  onItemSelectCompany(item: any) {
    this.companyCode = item.id;
    this.companyName = item.itemName;
    this.storeCode = 0;
    this.selectedItemsStore = null;
    this.storeList = item.stores;
  }

  onItemDeSelectCompany(item: any) {
    this.storeList = [];
    this.companyCode = null;
    this.selectedItemsStore = null;
  }

  onItemSelectStore(item: any) {
    this.storeCode = item.id;
    this.storeName = item.itemName;
  }
  
  onItemDeSelectStore(item: any) {
    this.storeCode = null;
    this.storeName = null;
  }

  //CASHIER OPEN LOG
  async openStore(){
    const _data = JSON.parse(localStorage.getItem('data'));
    const userName = JSON.parse(localStorage.getItem('user')).userName;

    return this._userService.getLatestLog(_data.userCode).then(
      async res => {
        // CONVERTIR OBJ PROMESA A JSON
        const jsonResponse = JSON.parse(JSON.stringify(res))
        var amountsHTML = ``;
        var newLog;
        var initialCash = 0;
        var previousLogDate;
        var previousWithdrawn = 0.00;

        if(jsonResponse.records && jsonResponse.records.length > 0){
          console.log(jsonResponse)
          previousLogDate = jsonResponse.records[0].logDate;
          previousWithdrawn = jsonResponse.records[0].amountWithdrawn;

          const paymentsLog = await JSON.parse(jsonResponse.records[0].paymentsJson)
          paymentsLog.forEach(payType => {
            amountsHTML+=`<b>${payType.name}: </b>Q${payType.amount}<br>`;
          });
          
          newLog = paymentsLog;
          newLog.forEach(payType => {
            if(payType.id == 0){
              payType.amount = payType.amount - previousWithdrawn;
              initialCash = payType.amount;
            }else{
              payType.amount = 0;
            }
          });
        }else{
          amountsHTML+=`<b>Efectivo: </b>Q0.00<br>`
          newLog = [{"id": 0, "name": "Efectivo", "amount": 0}];
          const now = new Date()
          const nowFormat = now.getFullYear()+"-"+ (now.getMonth() + 1) +"-"+now.getDate()+" "+now.getHours()+":"+now.getMinutes()+":"+now.getSeconds()
          previousLogDate = nowFormat;
        }

        swal.fire({
          title: "Apertura de caja:",
          html:
            '<div class="text-left">' +
            "<b>Código de usuario: </b>" +
            _data.userCode +
            "<br>" +
            "<b>Nombre: </b>" +
            userName +
            "<br>" +
            "<b>Empresa: </b>" +
            this.companyName +
            "<hr>" +
            "<b>Fecha y hora del último cierre: </b>"+
            previousLogDate +
            "<br>" +
            "<br>" +
            "<p>En el último cierre de caja se realizaron las siguientes ventas por los siguientes montos:</p>" +
            amountsHTML +
            "<br>" +
            "<br>" +
            "<b>Cantidad retirada en efectivo de la caja:</b> Q" +
            previousWithdrawn +
            "<hr>" +
            "<b>Fecha: </b>" +
            new Date().toLocaleDateString('es', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) +
            "<br>"+
            "<b>Hora: </b>" +
            new Date().toLocaleTimeString() +
            "<br></div>",
          input: 'textarea',
          inputLabel: 'Comentarios',
          inputPlaceholder: 'Escriba sus comentarios aquí...',
          inputAttributes: {
            'aria-label': 'Escriba sus comentarios aquí...'
          },
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          width: 650,
          confirmButtonText: "Abrir Caja",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        }).then(async result => {
          if(result.isConfirmed){
            const now = new Date()
            const nowFormat = now.getFullYear()+"-"+(now.getMonth() + 1)+"-"+now.getDate()+" "+now.getHours()+":"+now.getMinutes()+":"+now.getSeconds()
            const log = {
              "userCode": _data.userCode,
              "companyCode": this.companyCode,
              "storeCode": this.storeCode,
              "logType": 1,
              "paymentsJson": JSON.stringify(newLog),
              "amountWithdrawn": previousWithdrawn,
              "comments": result.value,
              "logDate": nowFormat,
            }

            this._userService.insertCashierLog(log).then(
              res => {
                if(JSON.parse(JSON.stringify(res)).statusCode == 201){
                  // console.log(res)

                  this.isStoreOpen = true;
                  _data.isStoreOpen = this.isStoreOpen;
                  localStorage.setItem('data', JSON.stringify(_data));
          
                  this._storeService.getPaymets(_data.storeCode).then(
                    response => {
                      if (response.result) {
                        var paymentMethodsList = new Array();
                        response.records.forEach(element => {
                          element.payMethodBusinessCode == 0 ? 
                            paymentMethodsList.push({"id": element.payMethodBusinessCode, "name": element.payMethodName, "amount": initialCash}) :
                            paymentMethodsList.push({"id": element.payMethodBusinessCode, "name": element.payMethodName, "amount": 0})
                        })
                        const paymentMethods = {
                          "userCode": _data.userCode,
                          "userName": userName,
                          "paymentType": paymentMethodsList,
                        }
                        localStorage.setItem('salesAmounts', JSON.stringify(paymentMethods))
                      } else {
                        console.log(response.message);
                      }
                    },
                    error => {
                      console.log(error);
                      swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'La caja no ha sido abierta debido a un error',
                      })
                    }
                  ).catch(
                    error => {
                      swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'La caja no ha sido abierta debido a un error',
                      })
                    }
                  )

                  swal.fire('Caja abierta!', '', 'success')
                }else{
                  swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'La caja no ha sido abierta debido a un error',
                  })
                }
              }
            ).catch(
              error => {
                swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'La caja no ha sido abierta debido a un error',
                })
              }
            )
          }
        })
      }
    )
  }

  //CASHIER CLOSE LOG
  async closeStore(){
    const _data = JSON.parse(localStorage.getItem('data'));
    const userName = JSON.parse(localStorage.getItem('user')).userName;
    const dayPaymentAmounts = JSON.parse(localStorage.getItem('salesAmounts'));
    var amountsHTML = ``;
    var amountWithdrawn;
    const now = new Date()
    const nowFormat = now.getFullYear()+"-"+ (now.getMonth() + 1) +"-"+now.getDate()+" "+now.getHours()+":"+now.getMinutes()+":"+now.getSeconds()

    dayPaymentAmounts.paymentType.forEach(payType => {
      amountsHTML+=`<b>${payType.name}: </b>Q${payType.amount}<br>`;
    });

    swal.fire({
      title: "Cierre de caja:",
      html:
        '<div class="text-left">' +
        "<b>Código de usuario: </b>" +
        _data.userCode +
        "<br>" +
        "<b>Nombre: </b>" +
        userName +
        "<br>" +
        "<b>Empresa: </b>" +
        this.companyName +
        "<hr>" +
        "<p>Montos por ventas:</p>" +
        amountsHTML +
        "<br>" +
        "<br>" +
        "<b>Cantidad retirada en efectivo de la caja (Q):</b>" +
        '<input id="swal-input1" class="swal2-input" type="number" min="0" max="999999" value="0.00">' +
        "<hr>" +
        "<b>Fecha: </b>" +
        new Date().toLocaleDateString('es', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) +
        "<br>"+
        "<b>Hora: </b>" +
        new Date().toLocaleTimeString() +
        "<br></div>",
      focusConfirm: false,
      input: 'textarea',
      inputLabel: 'Comentarios',
      inputPlaceholder: 'Escriba sus comentarios aquí...',
      inputAttributes: {
        'aria-label': 'Escriba sus comentarios aquí...'
      },
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      width: 650,
      confirmButtonText: "Cerrar Caja",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      preConfirm: () => {
        return amountWithdrawn = (<HTMLInputElement>document.getElementById('swal-input1')).value
      }
    }).then(
      async result => {
        if(result.isConfirmed){
          const log = {
            "userCode": dayPaymentAmounts.userCode,
            "companyCode": this.companyCode,
            "storeCode": this.storeCode,
            "logType": 0,
            "paymentsJson": JSON.stringify(dayPaymentAmounts.paymentType),
            "amountWithdrawn": parseFloat(amountWithdrawn),
            "comments": "",
            "logDate": nowFormat,
          }

          this._userService.insertCashierLog(log).then(
            res => {
              if(JSON.parse(JSON.stringify(res)).statusCode == 201){
                // console.log(res)

                this.isStoreOpen = false;
                _data.isStoreOpen = false;
                localStorage.setItem('data', JSON.stringify(_data));
                localStorage.removeItem('salesAmounts');

                swal.fire({
                  icon: 'warning',
                  title: 'Caja cerrada',
                  text: 'La caja ha sido cerrada...',
                })
              }else{
                swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'La caja no ha sido abierta',
                })
              }
            }
          )
        }
    })
  }
}
