import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { AccessService } from "src/app/services/access.service";
import { StoreService } from "src/app/services/store.service";
import { UserService } from "src/app/services/user.service";
import { CompanyService } from '../../services/company.service';
import { HttpClient } from "@angular/common/http";

var misc: any = {
  sidebar_mini_active: true
}

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Cargar Rutas
export const LoadRoutes: RouteInfo[] = [
  {
    path: "/home",
    title: "Tablero",
    type: "link",
    icontype: "tech_tv"
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  providers: [AccessService, CompanyService, StoreService]
})

export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  public user = {} as User;
  public role: number;
  public invoiceType: number;
  public invoiceFuel: number;
  loading;

  public empresa = {
    "path": "/",
    "title": "Empresa",
    "type": "sub",
    "icontype": "business_bank",
    "collapse": "operative",
    "isCollapsed": true,
    "children": []
  }

  public usuario = {
    path: "/",
    title: "Usuario",
    type: "sub",
    icontype: "users_circle-08",
    collapse: "operative",
    isCollapsed: true,
    children: []
  }

  public admin = {
    "path": "/",
    "title": "Administración",
    "type": "sub",
    "icontype": "tech_laptop",
    "collapse": "operative",
    "isCollapsed": true,
    "children": []
  }

  public conta = {
    path: "/",
    title: "Contabilidad",
    type: "sub",
    icontype: "business_chart-bar-32",
    collapse: "operative",
    isCollapsed: true,
    children: []
  }

  public compra = {
    path: "/",
    title: "Compra",
    type: "sub",
    icontype: "shopping_bag-16",
    collapse: "operative",
    isCollapsed: true,
    children: []
  }

  public facturacion = {
    path: "/",
    title: "Facturación",
    type: "sub",
    icontype: "education_paper",
    collapse: "operative",
    isCollapsed: true,
    children: []
  }

  public reporte = {
    path: "/",
    title: "Reporte",
    type: "sub",
    icontype: "business_chart-bar-32",
    collapse: "operative",
    isCollapsed: true,
    children: []
  }

  public manto = {
    path: "/",
    title: "Mantenimiento",
    type: "sub",
    icontype: "ui-2_settings-90",
    collapse: "operative",
    isCollapsed: true,
    children: []
  }

  constructor(
    private userService: UserService,
    private _accessService: AccessService,
    private _companyService: CompanyService,
    private _storeService: StoreService,
    private http:HttpClient) { }
    

  async ngOnInit() {
    await this.startValidation();
  }  

  public nameFirstLabel;
  public nameSecondLabel;
  public storeCode: number = 0;
  public companyCode: number = 0;
  public identify: string = "";
  public IP: string = "";

  getIPAddress()
  {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.IP = res.ip;
    });
    console.log('IP ', this.IP);
  }

  async startValidation() {
    if (localStorage.getItem('user') !== null) {
      this.user = JSON.parse(localStorage.getItem('user'));
      let arrName = this.user.userName.split(' ');
      if (arrName[0]) {
        this.nameFirstLabel = arrName[0];
        arrName[1] ? this.nameFirstLabel += ` ${arrName[1]}` : '';
      }
      if (arrName[2] || arrName[3]) {
        this.nameSecondLabel = arrName[2];
        arrName[3] ? this.nameSecondLabel += ` ${arrName[3]}` : '';
      }
    } else {
      this.logOut();
    }
    if (this.user) {

      //Inicializar Sidebar
      if (this.user !== undefined) {
        this.loading = true;

        const _data = JSON.parse(localStorage.getItem('data'));

        console.log('data ', _data);
        if (_data) {
          this.storeCode = _data.storeCode;
          this.companyCode = _data.companyCode;
          this.identify = _data.identify;
          this.IP = _data.IP;
        }

        //return await this.userService.oneUserCognitoBackend(this.user.userCognitoCode).then(
        return await this.userService.oneUserCognitoBackend(this.user.userCognitoCode, this.identify, this.IP).then(
          async result => {
            const userDB = result.records[0];
            if (userDB !== undefined) {
              userDB.userPassword = undefined;
              localStorage.setItem('user', JSON.stringify(userDB));
              const _data = JSON.parse(localStorage.getItem('data'));

              if (_data) {
                this.storeCode = _data.storeCode;
                this.companyCode = _data.companyCode;
              } else {
                let userData = await this.userService.oneUserEmailBackend(userDB.userEmail);
                this.storeCode = userData.records[0].storeCode;
                this.companyCode = userData.records[0].companyCode;
              };

              const accessGetOne = await this._accessService.getAllAccessByRole(userDB.userRole);
              this.accessList = accessGetOne.records;
              const storeGetOne = await this._storeService.getOne(this.storeCode);
              this.invoiceType = storeGetOne.records[0].storeInvoiceType;
              const invoice = this.invoiceType === 1 ? 'inventory-' : 'description-';
              const companyGetOne = await this._companyService.getOne(this.companyCode);
              this.invoiceFuel = companyGetOne.records[0].companyInvoiceFuel;

              const a = '>>'
              //////////COMPANY
              const company = this.accessList.find(x => x.accessRoute === 'company');
              (company) && (this.empresa.children.push({ path: company.accessRoute, title: company.accessName, ab: a }));

              const owner = this.accessList.find(x => x.accessRoute === 'owner');
              (owner) && (this.empresa.children.push({ path: owner.accessRoute, title: owner.accessName, ab: a }));

              const store = this.accessList.find(x => x.accessRoute === 'store');
              (store) && (this.empresa.children.push({ path: store.accessRoute, title: store.accessName, ab: a }));

              const manager = this.accessList.find(x => x.accessRoute === 'manager');
              (manager) && (this.empresa.children.push({ path: manager.accessRoute, title: manager.accessName, ab: a }));

              //////////USER
              const user = this.accessList.find(x => x.accessRoute === 'user');
              (user) && (this.usuario.children.push({ path: user.accessRoute, title: user.accessName, ab: a }));

              const multi_company_user = this.accessList.find(x => x.accessRoute === 'multi-company-user');
              (multi_company_user) && (this.usuario.children.push({ path: multi_company_user.accessRoute, title: multi_company_user.accessName, ab: a }));

              const permission = this.accessList.find(x => x.accessRoute === 'permission');
              (permission) && (this.usuario.children.push({ path: permission.accessRoute, title: permission.accessName, ab: a }));

              //////////ACCOUNTING
              const payIn = this.accessList.find(x => x.accessRoute === 'payment-invoice');
              (payIn) && (this.conta.children.push({ path: payIn.accessRoute, title: payIn.accessName, ab: a }));

              const payPur = this.accessList.find(x => x.accessRoute === 'payment-purchase');
              (payPur) && (this.conta.children.push({ path: payPur.accessRoute, title: payPur.accessName, ab: a }));

              const payRec = this.accessList.find(x => x.accessRoute === 'payment-records');
              (payRec) && (this.conta.children.push({ path: payRec.accessRoute, title: payRec.accessName, ab: a }));

              const balance = this.accessList.find(x => x.accessRoute === 'balance-sheet');
              (balance) && (this.conta.children.push({ path: balance.accessRoute, title: balance.accessName, ab: a }));

              const dairy = this.accessList.find(x => x.accessRoute === 'dairy');
              (dairy) && (this.conta.children.push({ path: dairy.accessRoute, title: dairy.accessName, ab: a }));

              //////////ADMIN
              const client = this.accessList.find(x => x.accessRoute === 'client');
              (client) && (this.admin.children.push({ path: client.accessRoute, title: client.accessName, ab: a }));

              const provider = this.accessList.find(x => x.accessRoute === 'provider');
              (provider) && (this.admin.children.push({ path: provider.accessRoute, title: provider.accessName, ab: a }));

              const inventory = this.accessList.find(x => x.accessRoute === 'inventory');
              (inventory && this.invoiceType === 1) &&  (this.admin.children.push({ path: inventory.accessRoute, title: inventory.accessName, ab: a }));

              const warehouse = this.accessList.find(x => x.accessRoute === 'warehouse');
              (warehouse && this.invoiceType === 1) &&  (this.admin.children.push({ path: warehouse.accessRoute, title: warehouse.accessName, ab: a }));

              const transfer = this.accessList.find(x => x.accessRoute === 'transfer');
              (transfer && this.invoiceType === 1) &&  (this.admin.children.push({ path: transfer.accessRoute, title: transfer.accessName, ab: a }));

              const trans_process = this.accessList.find(x => x.accessRoute === 'transfer-process');
              (trans_process && this.invoiceType === 1) &&  (this.admin.children.push({ path: trans_process.accessRoute, title: trans_process.accessName, ab: a }));

              //////////PURCHASE
              const purchaseIn = this.accessList.find(x => x.accessRoute === 'purchase-insert');
              (purchaseIn) &&  (this.compra.children.push({ path: purchaseIn.accessRoute, title: purchaseIn.accessName, ab: a }));
              
              const purchasePe = this.accessList.find(x => x.accessRoute === 'purchase-pending');
              (purchasePe) &&  (this.compra.children.push({ path: purchasePe.accessRoute, title: purchasePe.accessName, ab: a }));
              
              const purchasePro = this.accessList.find(x => x.accessRoute === 'purchase-process');
              (purchasePro) &&  (this.compra.children.push({ path: purchasePro.accessRoute, title: purchasePro.accessName, ab: a }));
              
              const purchaseVi = this.accessList.find(x => x.accessRoute === 'purchase-view');
              (purchaseVi) &&  (this.compra.children.push({ path: purchaseVi.accessRoute, title: purchaseVi.accessName, ab: a }));

              //////////DTE
              const billing = this.accessList.find(x => x.accessRoute === 'billing');
              (billing) && (this.facturacion.children.push({ path: invoice + billing.accessRoute, title: billing.accessName, ab: a }));

              const fuel = this.accessList.find(x => x.accessRoute === 'inventory-billing-fuel');
              (fuel) && (this.facturacion.children.push({ path: fuel.accessRoute, title: fuel.accessName, ab: a }));

              const receiptCancel = this.accessList.find(x => x.accessRoute === 'cancellation-receipt');
              (receiptCancel) && (this.facturacion.children.push({ path: receiptCancel.accessRoute, title: receiptCancel.accessName, ab: a }));

              const receiptReport = this.accessList.find(x => x.accessRoute === 'report-receipt-company');
              (receiptReport) && (this.facturacion.children.push({ path: receiptReport.accessRoute, title: receiptReport.accessName, ab: a }));

              const recurrent = this.accessList.find(x => x.accessRoute === 'invoice-recurrent');
              (recurrent) && (this.facturacion.children.push({ path: recurrent.accessRoute, title: recurrent.accessName, ab: a }));

              const exportacion = this.accessList.find(x => x.accessRoute === 'export-invoice');
              (exportacion) && (this.facturacion.children.push({ path: invoice + exportacion.accessRoute, title: exportacion.accessName, ab: a }));

              const exchange = this.accessList.find(x => x.accessRoute === 'exchange-note');
              (exchange) && (this.facturacion.children.push({ path: invoice + exchange.accessRoute, title: exchange.accessName, ab: a }));

              const exporta = this.accessList.find(x => x.accessRoute === 'export-note');
              (exporta) && (this.facturacion.children.push({ path: invoice + exporta.accessRoute, title: exporta.accessName, ab: a }));

              const debit = this.accessList.find(x => x.accessRoute === 'debit-note');
              (debit) && (this.facturacion.children.push({ path: invoice + debit.accessRoute, title: debit.accessName, ab: a }));

              const credit = this.accessList.find(x => x.accessRoute === 'credit-note');
              (credit) && (this.facturacion.children.push({ path: invoice + credit.accessRoute, title: credit.accessName, ab: a }));

              const annul = this.accessList.find(x => x.accessRoute === 'cancellation');
              (annul) && (this.facturacion.children.push({ path: annul.accessRoute, title: annul.accessName, ab: a }));

              const reprint = this.accessList.find(x => x.accessRoute === 'reprint');
              (reprint) && (this.facturacion.children.push({ path: reprint.accessRoute, title: reprint.accessName, ab: a }));

              const repStore = this.accessList.find(x => x.accessRoute === 'report-store');
              (repStore) && (this.facturacion.children.push({ path: repStore.accessRoute, title: repStore.accessName, ab: a }));

              const repCompany = this.accessList.find(x => x.accessRoute === 'report-company');
              (repCompany) && (this.facturacion.children.push({ path: repCompany.accessRoute, title: repCompany.accessName, ab: a }));

              //////////MANTENIMIENTO
              const cliCat = this.accessList.find(x => x.accessRoute === 'client-category');
              (cliCat) && (this.manto.children.push({ path: cliCat.accessRoute, title: cliCat.accessName, ab: a }))

              const proCat = this.accessList.find(x => x.accessRoute === 'provider-category');
              (proCat) && (this.manto.children.push({ path: proCat.accessRoute, title: proCat.accessName, ab: a }));

              const product = this.accessList.find(x => x.accessRoute === 'product');
              (product) && (this.manto.children.push({ path: product.accessRoute, title: product.accessName, ab: a }));

              const comType = this.accessList.find(x => x.accessRoute === 'company-type');
              (comType) && (this.manto.children.push({ path: comType.accessRoute, title: comType.accessName, ab: a }));

              const comPlan = this.accessList.find(x => x.accessRoute === 'company-plan');
              (comPlan) && (this.manto.children.push({ path: comPlan.accessRoute, title: comPlan.accessName, ab: a }));

              const comPS = this.accessList.find(x => x.accessRoute === 'phrase-stage');
              (comPS) && (this.manto.children.push({ path: comPS.accessRoute, title: comPS.accessName, ab: a }));

              // Complemento Sylvania
              const xml_wm = this.accessList.find(x => x.accessRoute === 'xml-wm');
              (xml_wm) && (this.facturacion.children.push({ path: xml_wm.accessRoute, title: xml_wm.accessName, ab: a }))

              const xml_fcam = this.accessList.find(x => x.accessRoute === 'xml-fcam');
              (xml_fcam) && (this.facturacion.children.push({ path: xml_fcam.accessRoute, title: xml_fcam.accessName, ab: a }))

              const xml_ncred = this.accessList.find(x => x.accessRoute === 'xml-ncred');
              (xml_ncred) && (this.facturacion.children.push({ path: xml_ncred.accessRoute, title: xml_ncred.accessName, ab: a }))

              const reprint_exchange = this.accessList.find(x => x.accessRoute === 'reprint-exchange');
              (reprint_exchange) && (this.facturacion.children.push({ path: reprint_exchange.accessRoute, title: reprint_exchange.accessName, ab: a }))

              const reprint_exchange_bill = this.accessList.find(x => x.accessRoute === 'reprint-exchange-bill');
              (reprint_exchange_bill) && (this.facturacion.children.push({ path: reprint_exchange_bill.accessRoute, title: reprint_exchange_bill.accessName, ab: a }))

              const cancellation_exchange_bill = this.accessList.find(x => x.accessRoute === 'cancellation-exchange-bill');
              (cancellation_exchange_bill) && (this.facturacion.children.push({ path: cancellation_exchange_bill.accessRoute, title: cancellation_exchange_bill.accessName, ab: a }))

              const preventa = this.accessList.find(x => x.accessRoute === 'preventa');
              (preventa) && (this.facturacion.children.push({ path: preventa.accessRoute, title: preventa.accessName, ab: a }));

              const restaurant = this.accessList.find(x => x.accessRoute === 'restaurant');
              (restaurant) && (this.facturacion.children.push({ path: restaurant.accessRoute, title: restaurant.accessName, ab: a }));

              const restaurantbasic = this.accessList.find(x => x.accessRoute === 'restaurant-basic');
              (restaurantbasic) && (this.facturacion.children.push({ path: restaurantbasic.accessRoute, title: restaurantbasic.accessName, ab: a }));

              const restaurantCommand = this.accessList.find(x => x.accessRoute === 'restaurant-command');
              (restaurantCommand) && (this.facturacion.children.push({ path: restaurantCommand.accessRoute, title: restaurantCommand.accessName, ab: a }));              

              const report_sale = this.accessList.find(x => x.accessRoute === 'report-sale');
              (report_sale) && (this.reporte.children.push({ path: report_sale.accessRoute, title: report_sale.accessName, ab: a }));

              const report_book = this.accessList.find(x => x.accessRoute === 'report-book');
              (report_book) && (this.reporte.children.push({ path: report_book.accessRoute, title: report_book.accessName, ab: a }));

              (this.empresa.children.length > 0) && (LoadRoutes.push(this.empresa));
              (this.usuario.children.length > 0) && (LoadRoutes.push(this.usuario));
              (this.conta.children.length > 0) && (LoadRoutes.push(this.conta));
              (this.admin.children.length > 0) && (LoadRoutes.push(this.admin));
              (this.compra.children.length > 0) && (LoadRoutes.push(this.compra));
              (this.facturacion.children.length > 0) && (LoadRoutes.push(this.facturacion));
              (this.reporte.children.length > 0) && (LoadRoutes.push(this.reporte));
              (this.manto.children.length > 0) && (LoadRoutes.push(this.manto));
              this.menuItems = LoadRoutes.filter(menuItem => menuItem);

            } else {
              this.logOut();
            }
          }
        ).catch(
          (err) => {
            console.log(err);
          }
        ).finally(() => {
          this.loading = false;
        });
      }
    }
  }

  public accessList: any[] = new Array();

  logOut() {
    this.userService.userLogout();
    LoadRoutes.length = 1;
    this.empresa.children = [];
    this.usuario.children = [];
    this.admin.children = [];
    this.conta.children = [];
    this.compra.children = [];
    this.facturacion.children = [];
    this.manto.children = [];
    window.location.reload();
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      var element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        var style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function () {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function () {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function () {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function () {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function () {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
}
