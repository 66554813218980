import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Purchase } from '../models/purchase';
import { PurchaseDetail } from '../models/purchaseDetail';
import { environment } from 'src/environments/environment';


@Injectable()
export class PurchaseService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL PURCHASE BY COMPANY/STORE
    getAllByCompany(companyCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'erprest-purchase/company/' + companyCode, { headers: headers }).toPromise();
    }

    getAllByCompany_Status(companyCode, status): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-purchase/one/' + companyCode + '/' + status, { headers: headers }).toPromise();
    }

    // GET ONE
    getOne(code): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-purchase/unique/' + code, { headers: headers }).toPromise();
    }

    // INSERT PURCHASE
    insert(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-purchase', params, { headers: headers }).toPromise();
    }

    // UPDATE PURCHASE
    update(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-purchase', params, { headers: headers }).toPromise();
    }

    // UPDATE PURCHASE
    updateDairy(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-purchase/dairy', params, { headers: headers }).toPromise();
    }    

    // AUTORIZED PURCHASE
    authorized(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-purchase/Authorized', params, { headers: headers }).toPromise();
    }

    // CANCEL PURCHASE
    cancel(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-purchase/Cancel', params, { headers: headers }).toPromise();
    }

    // PROCESS PURCHASE
    process(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-purchase/process', params, { headers: headers }).toPromise();
    }

    // REPORT ONE ITEM PURCHASE
    reportItem(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-purchase/report/detail', params, { headers: headers }).toPromise();
    }

    // REPORT DETAIL PURCHASE
    reportDetail(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-purchase/report/detail/by/date', params, { headers: headers }).toPromise();
    }
}