import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { AccessService } from 'src/app/services/access.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PurchaseService } from 'src/app/services/purchase.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({

  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss'],
  providers: [PurchaseService, AccessService, UtilsService]
})
export class PurchaseDetailComponent implements OnInit {
  loading;
  // Variables auxiliares
  public isBeginning: boolean;
  public isDetail: boolean;
  public isDisabled: boolean;
  public userCommerceCode: number;
  public begDate: string;
  public endDate: string;
  public reasonForCancellation;
  public userCode: number;
  public storeName: string;
  public storeCode: number;
  public companyCode: number;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      proveedor: {
        title: 'Proveedor'
      },
      orden: {
        title: 'No. Orden'
      },
      cantidad: {
        title: 'Cantidad',
        type: 'number'
      },
      codigo: {
        title: 'Código',
      },
      descripcion: {
        title: 'Descripcion'
      },
      precio: {
        title: 'Precio',
        type: 'number'
      },
      autorizo: {
        title: 'autorizo'
      },
      proceso: {
        title: 'proceso'
      },
      sucursal: {
        title: 'Sucursal'
      }
    }
  };

  totalTotal: number;

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp: any[] = new Array();
  public purchaseList: any[] = new Array();

  constructor(
    private _utilsService: UtilsService,
    private _purchaseService: PurchaseService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeName = _data.storeName ? _data.storeName.toString() : null;

    //DATE
    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');

    this.totalTotal = 0;
    this.viewBeginning();
  }

  ///////////////////////PANTALLAS////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isDetail = false;
  }

  viewDetail() {
    this.isBeginning = false;
    this.isDetail = true;
  }

  ///////////////////////FUNCIONES PARA TABLAS/////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.purchaseList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////
  //Ver todas las factura
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      const json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this.temp = [];
      this.purchaseList = [];

      this._purchaseService.reportDetail(json).then(
        async (response) => {
          if (response.result == true) {
            console.log(response.records)
            this.purchaseList = response.records;
            // response.records.forEach(e => {
            //   this.purchaseList.push({
            //     fecha: e.fecha,
            //     proveedor: e.proveedor,
            //     orden: e.orden,
            //     cantidad: e.cantidad,
            //     codigo: e.codigo,
            //     descripcion: e.descripcion,
            //     precio: e.precio,
            //     sucursal: e.sucursal,
            //   });
            // });
            this.temp = this.purchaseList;

            this.isDisabled = false;
            this.viewDetail();
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      this._utilsService.showNotification(2, "Ingresar Datos");
    }
  }

  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-detalle-compras.xlsx');
  }
}
