import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InvoiceHeader } from '../models/invoiceHeader';

@Injectable()
export class InvoiceService {
    public url: string;
    public urlFactura: string;

    constructor(
        private _http: HttpClient,        
    ) {
        this.url = environment.apiUrl;
    }

    ///////////////////////INSERT///////////////////////
    // INSERT RECURRENT
    insertRecurrentBilling(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-job', params, { headers }).toPromise();
    }

    // DELETED RECURRENT INVOICE
    updateRecurrentBilling(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-job', params, { headers: headers }).toPromise();
    }

    //GET RECURRENT INVOICE
    recurrentInvoice(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'erprest-job/company/' + code, { headers: headers }).toPromise();
    }

    // INSERT INFILE / MEGAPRINT
    insertBilling(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoice', params, { headers }).toPromise();
    }

    // INSERT RECEIPT
    insertBillingReceipt(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-receipt', params, { headers }).toPromise();
    }

    // INSERT FUEL MEGAPRINT
    insertBillingMegaprintFuel(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoice/fuel', params, { headers }).toPromise();
    }

    // INSERT CREDIT NOTE
    insertCreditNote(credit): Promise<any> {
        const params = JSON.stringify(credit);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-creditNote', params, { headers }).toPromise();
    }                                    

    // INSERT DEBIT NOTE
    insertDebitNote(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-debitNote', params, { headers }).toPromise();
    }

    // INSERT EXPORT
    insertInvoiceExport(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoiceExport', params, { headers }).toPromise();
    }

    // INSERT CHANGE
    insertInvoiceExchange(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoiceExchange', params, { headers }).toPromise();
    }

    ///////////////////////UPDATE///////////////////////
    updateInvoiceDuesQuantity(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-invoice', params, { headers: headers }).toPromise();
    }

    updateInvoiceExchangeQuantity(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-invoiceExchange', params, { headers: headers }).toPromise();
    }

    ///////////////////////GET///////////////////////
    //GET ONE INVOICE BY MEGAPRINT
    getOneMegaprint(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'erprest-invoice/one/' + code + '/megaprint', { headers: headers }).toPromise();
    }

    getOneMegaprintExchange(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'erprest-invoiceExchange/one/' + code + '/megaprint', { headers: headers }).toPromise();
    } 

    //GET ONE INVOICE FOR CREDIT/DEBIT NOTE
    getOneAsArray(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + '/erprest-invoice/one/' + code, { headers: headers }).toPromise();
    } 

    getAllCreditNote(json): Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-creditNote/report/store', params, { headers: headers }).toPromise();
    }

    getAllDebitNote(json): Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-debitNote/report/store', params, { headers: headers }).toPromise();
    } 

    ///////////////////////ANULMENT/CANCEL///////////////////////
    // GET HEADERS INVOICE LIST BY COMPANY 
    getAllByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoice/report/company', params, { headers: headers }).toPromise();
    }

    // GET HEADERS + RETENTION INVOICE LIST BY COMPANY 
    getAllByCompanyRetention(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoice/report/company/extra', params, { headers: headers }).toPromise();
    }

    // GET HEADERS EXCHANGE BILL LIST BY COMPANY 
    getAllExchangeByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoiceExchange/report/company', params, { headers: headers }).toPromise();
    }

    getAllByCompanyReceipt(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-receipt/report/company', params, { headers: headers }).toPromise();
    }

    // INSERT ANULMENT
    annular(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoice/cancel/', params, { headers: headers }).toPromise();
    }

    // INSERT ANULMENT EXCHANGE BILL
    annularExchange(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoiceExchange/cancel', params, { headers: headers }).toPromise();
    }

    annularReceipt(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-receipt/cancel/', params, { headers: headers }).toPromise();
    }

    ///////////////////////REPORT///////////////////////
    // BY STORE
    getReportByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-sale/report/company', params, { headers: headers }).toPromise();
    }

    // BY STORE
    getReportByCompanyDetail(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-sale/report/company/detail', params, { headers: headers }).toPromise();
    }

    // BY COMPANY
    getReportAllCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-sale/report/company/header/company', params, { headers: headers }).toPromise();
    }

    // BY COMPANY RECEIPT
    getReportReceiptAllCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-sale/reportreceipt/company/header/company', params, { headers: headers }).toPromise();
    }

    // GET XML CERTIFICATE    
    xmlInvoiceExchange(invoiceCode: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `erprest-invoiceExchange/xml/one/${invoiceCode}`, { headers: headers }).toPromise();
    }
    
    xmlCreditNote(creditNoteCode: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `erprest-creditNote/xml/one/${creditNoteCode}`, { headers: headers }).toPromise();
    }

    changeStatusPresale(file_name, status): Promise<any> {
        let body = JSON.stringify({
            bucket_name: "nail-center",
            folder_name: `Preventas/${file_name}`,          
            status : status
        });

        let headers = new HttpHeaders().set("Content-Type", "application/json");
        return this._http
          .post(
            "https://us-central1-clever-375004.cloudfunctions.net/change_status",
            body,
            { headers }
          )
          .toPromise();
    }

    updatePresale(file_name, newGuid, invoice, pdfHmtl, correlativo): Promise<any> {
        let body = JSON.stringify({
            bucket_name: "nail-center",
            carpeta: "Preventas",
            filename: file_name,
            content: btoa(pdfHmtl),
            correlativo,
            content_type: "text/html",
            invoice,
            newGuid,
        });

        let headers = new HttpHeaders().set("Content-Type", "application/json");
        return this._http
          .post(
            "https://us-central1-clever-375004.cloudfunctions.net/update_File",
            body,
            { headers }
          )
          .toPromise();
    }

    sendEmail_Presale(data_to_send): Promise<any> {        
        let body = JSON.stringify({
          reciever_mail: data_to_send.email,
          presale_number: data_to_send.presale_number.toString(),
          html_content: data_to_send.html_content,
        });

        console.log(body)

        let headers = new HttpHeaders().set("Content-Type", "application/json");
        return this._http
          .post(
            "https://us-central1-clever-375004.cloudfunctions.net/send_Email",
            body,
            { headers }
          )
          .toPromise();
    }

}
