import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { Data, Router } from '@angular/router';
import swal from "sweetalert2";
import { Store } from 'src/app/models/store';
import { Company } from 'src/app/models/company';
import { XmlService } from 'src/app/services/xml.service';
import { PrintService } from 'src/app/services/print.service';
import { StoreService } from 'src/app/services/store.service';
import { UtilsService } from 'src/app/services/utils.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { CompanyService } from 'src/app/services/company.service';

//import { factura } from 'src/app/services/factura';

interface Walmart {
  WMEnviarGLN: string,
  WMFechaOrden: string,
  WMNumeroOrden: string,
  WMNumeroVendedor: string,
  WMNumeroRecepcion: string,
}

@Component({
  selector: 'app-xml-load',
  templateUrl: './xml-load.component.html',
  styleUrls: ['./xml-load.component.scss', '../../app.component.scss'],
  providers: [UtilsService, PrintService, CompanyService, StoreService, InvoiceService, XmlService]
})
export class XmlLoadComponent implements OnInit {
  public store: Store;
  public company: Company;
  public data: Data = {} as Data;
  public walmart: Walmart = {} as Walmart;
  public typeDTE: string = '';
  public nameDTE: string = '';
  public closeResult: string = '';
  public loading: boolean = false;
  public disabled: boolean = false;
  public walmartDTE: boolean = false;

  public file: any = {};
  @ViewChild('file_input') file_input: ElementRef;

  // variables data table
  public activeRow: any = {};
  public selected: any[] = [];
  public entries: number = 10;
  public rowsDocument: any[] = new Array();
  public documentList: any[] = new Array();
  public error: boolean = false;
  public errorCount: number = 0;
  public rowsDocumentError: any[] = new Array();
  public rowsDocumentCertified: any[] = new Array();

  constructor(
    public router: Router,
    public config: NgbModalConfig,
    private modalService: NgbModal,
    private _xmlService: XmlService,
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _storeService: StoreService,
    private _companyService: CompanyService,
    private _invoiceService: InvoiceService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    //this.respuesta = factura.megaprint;
  }

  //public respuesta: any;

  async ngOnInit(): Promise<void> {
    const urlTree = this.router.parseUrl(this.router.url);
    const location = urlTree.root.children.primary.segments[0].path;
    switch (location) {
      case 'xml-fcam':
        this.nameDTE = 'Factura Cambiaria';
        this.typeDTE = 'FACTURA';
        break;
      case 'xml-wm':
        this.nameDTE = 'Factura Cambiaria Walmart';
        this.typeDTE = 'FACTURA';
        this.walmartDTE = true;
        break;
      case 'xml-ncred':
        this.nameDTE = 'Nota de Crédito';
        this.typeDTE = 'NOTACREDITO';
        break;
    }

    this.loading = true;
    const _data = JSON.parse(localStorage.getItem('data'));
    this.data.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.data.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.data.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    const store = await this._storeService.getOne(this.data.storeCode);
    const company = await this._companyService.getOne(this.data.companyCode);
    this.store = store.records[0];
    this.company = company.records[0];
    this.loading = false;

    console.log(this.company);
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.rowsDocument = this.documentList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  deleteFunction($event) {
    $event.preventDefault();
    const row: any = this.activeRow;
    const index = this.rowsDocument.findIndex(x => x.name === row.name);
    this.rowsDocument.splice(index, 1);
  }

  ///////////////////////FUNCION LEER DOCUMENTOS///////////////////////
  async fileChanged(f) {
    this.loading = true;
    this.disabled = true;

    for (let i = 0; i < f.target.files.length; i++) {
      const file = f.target.files.item(i)
      const base64 = await this.convertBase64(file);
      const text = await file.text();
      const json: any = await this._xmlService.convertXMLtoJSON(this.typeDTE, this.walmartDTE, text, this.data.userCode, this.company, this.store, base64.toString());
      const error = '';

      console.log('json');
      console.log(json);
      console.log(json.hasOwnProperty('errorClient'));      

      if (json != false) {
        if(json == true){
          console.log("error monto");
          this._utilsService.showNotification(5, 'Revise la información de NIT/CUI/EXT y el MONTO TOTAL; no se permite operar como CF documentos mayores a Q.2,500.00 ');
        }
        else{
          if(json.hasOwnProperty('errorClient')){
            this._utilsService.showNotification(5, json.errorDetail);
          }else{
            this.rowsDocument.push({
              name: f.target.files[i].name,
              type: f.target.files[i].type,
              text: text.toString(),
              error: error,
              json: json
            });
          }          
        }
        
      }
      else {
        this._utilsService.showNotification(2, `Documento ${f.target.files[i].name} no permitido`);
      }
    };
    this.rowsDocument = [...this.rowsDocument];
    this.documentList = this.rowsDocument;
    this.file_input.nativeElement.value = null;
    this.disabled = false;
    this.loading = false;
    this.file = {};
  }

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  ///////////////////////FACTURAR XML///////////////////////
  async generate() {
    this.error = false;
    this.errorCount = 0;
    this.loading = true;
    this.disabled = true;

    for (const json of this.rowsDocument) {
      console.log(json.name);
      console.log(json);
            
      const hour = await this._utilsService.dateTime('HH:mm:ss');

      let adenda_walmart = false;
      if (this.walmartDTE === true) {
        ((json.json.walmart.WMEnviarGLN).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMFechaOrden).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMNumeroOrden).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMNumeroVendedor).length == 0) && (adenda_walmart = true);
        ((json.json.walmart.WMNumeroRecepcion).length == 0) && (adenda_walmart = true);
      }

      if (this.typeDTE == 'NOTACREDITO') {
        //json.json.creditNote.creditNoteDate += ` ${hour}`;

        await this._invoiceService.insertCreditNote(json.json).then(
          async (response) => {
            if (response.result) {              
              const xml = response.records.megaprintServiceResponse.signResponse.signMegaprint.FirmaDocumentoResponse.xml_dte[0];
              this.rowsDocumentCertified.push({ name: json.name, pdf: response.creditNotePdf, xml: xml })              
            } else {
              this.error = true;
              this.errorCount++;
              console.log(`Document: ${json.name}. Error: ${response.errorMessage}`);
              this.rowsDocumentError.push({ name: json.name, error: response.errorMessage })
              this.rowsDocumentError = await [...this.rowsDocumentError];              
            }
          },
          error => {
            console.log(`Document: ${json.name}. Error: ${error}`);
          });
      } else if (this.typeDTE == 'FACTURA' && adenda_walmart == false) { 
        json.json.invoiceExchange.invoiceExchangeDate += ` ${hour}`;

        await this._invoiceService.insertInvoiceExchange(json.json).then(
          async (response) => {
            if (response.result) {
              const xml = response.records.megaprintServiceResponse.signResponse.signMegaprint.FirmaDocumentoResponse.xml_dte[0];
              this.rowsDocumentCertified.push({ name: json.name, pdf: response.invoiceExchangePdf, xml: xml })
            } else {
              this.error = true;
              this.errorCount++;
              console.log(`Document: ${json.name}. Error: ${response.errorMessage}`);              
              this.rowsDocumentError.push({ name: json.name, error: response.errorMessage })
              this.rowsDocumentError = await [...this.rowsDocumentError];              
            }
          },
          error => {
            console.log(`Document: ${json.name}. Error: ${error}`);
          });
      } 
      else if (adenda_walmart == true) {        
        this.error = true;
        this.errorCount++;
        this.rowsDocumentError.push({ name: json.name, error: 'Adendas Walmart no ingresadas' })
        this.rowsDocumentError = await [...this.rowsDocumentError];
      }
    }

    if (this.error) {
      this._utilsService.showNotification(2, `Hubieron ${this.errorCount} documentos no certificados. ¡Intenta de nuevo!`);
    } else {
      this._utilsService.showNotification(1, `¡Todos los documentos se certificaron exitosamente!`);
    }
    this.rowsDocument = await [...this.rowsDocumentCertified];

    this.loading = false;
    this.disabled = false;
  }

  ///////////////////////FUNCION MODALES///////////////////////
  viewNCFunction($event) {
    $event.preventDefault();

    console.log(this.activeRow);
    let detail = '';
    for (const e of this.activeRow.json.detail) {
      detail += `<tr><td>${e.description}</td><td>${e.total}</td></tr>`;
    }

    swal.fire({
      title: "Datos:",
      html:
        `<div>
        <b>Nit/CUI/EXT: </b> ${this.activeRow.json.receiver.receiverTaxDocument}<br>
        <b>Nombre: </b> ${this.activeRow.json.receiver.receiverName}<br>
        <b>Dirección: </b> ${this.activeRow.json.receiver.receiverAddress}<hr>
        <table style="width:100%">
        <tr><th>Item</th><th>Subtotal</th></tr>
        ${detail}
        </table>
        <hr>Moneda: ${this.activeRow.json.creditNote.creditNoteCurrency}.  Cambio: ${this.activeRow.json.creditNote.creditNoteChangeRate}<br> 
        <b>Total: ${this.activeRow.json.creditNote.creditNoteChangeRate}</b><hr>
        <b>Fecha Nota: </b> ${this.activeRow.json.creditNote.creditNoteDate}<br>
        <b>Fecha Factura: </b>${this.activeRow.json.creditNote.creditNoteSourceDocumentIssuanceDate}<br>
        <b>Concepto Nota: </b>${this.activeRow.json.creditNote.creditNoteFitMotif}<br>        
        <b>Autorización: </b>${this.activeRow.json.creditNote.creditNoteOrigialDocumentAuthorizationNumber}<br>
        <b>Autorización: </b>
        <input type="text" class="form-control form-control-sm" value='${this.activeRow.json.creditNote.creditNoteOrigialDocumentAuthorizationNumber}/>
        </div>`,
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    });
  }

  viewFunction($event) {
    $event.preventDefault();

    let detail = '';
    for (const e of this.activeRow.json.detail) {
      detail += `<tr><td>${e.description}</td><td>${e.total}</td></tr>`;
    }

    swal.fire({
      title: "Datos:",
      html:
        `<div>
          <b>Emisión: </b> ${this.activeRow.json.invoiceExchange.invoiceExchangeDate}  |  <b>Vencimiento: </b> ${this.activeRow.json.detailsComplement[0].invoiceExchangeSubscriptionDate}<hr>
          <b>Nit/CUI/EXT: </b> ${this.activeRow.json.receiver.receiverTaxDocument}<br>
          <b>Nombre: </b> ${this.activeRow.json.receiver.receiverName}<br>
          <b>Dirección: </b> ${this.activeRow.json.receiver.receiverAddress}<hr>
            <table style="width:100%">
              <tr><th>Item</th><th>Subtotal</th></tr>
              ${detail}
            </table>
          <hr>Moneda: ${this.activeRow.json.invoiceExchange.invoiceExchangeCurrency}.  Cambio: ${this.activeRow.json.invoiceExchange.invoiceExchangeChangeRate}<br> 
          <b>Total: ${this.activeRow.json.invoiceExchange.invoiceExchangeTotal}</b>
        </div>`,
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    });
  }

  viewFunctionWl(content, documentName) {
    this.walmart.WMEnviarGLN = this.activeRow.json.walmart.WMEnviarGLN;
    this.walmart.WMFechaOrden = this.activeRow.json.walmart.WMFechaOrden;
    this.walmart.WMNumeroOrden = this.activeRow.json.walmart.WMNumeroOrden;
    this.walmart.WMNumeroVendedor = this.activeRow.json.walmart.WMNumeroVendedor;
    this.walmart.WMNumeroRecepcion = this.activeRow.json.walmart.WMNumeroRecepcion;

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then(
      async (result) => {
        const index = await this.rowsDocument.findIndex(x => x.name === documentName);
        this.rowsDocument[index].json.walmart.WMEnviarGLN = this.walmart.WMEnviarGLN;
        this.rowsDocument[index].json.walmart.WMFechaOrden = this.walmart.WMFechaOrden;
        this.rowsDocument[index].json.walmart.WMNumeroOrden = this.walmart.WMNumeroOrden;
        this.rowsDocument[index].json.walmart.WMNumeroVendedor = this.walmart.WMNumeroVendedor;
        this.rowsDocument[index].json.walmart.WMNumeroRecepcion = this.walmart.WMNumeroRecepcion;

        // hacer el push y limpiar variable
        this.walmart = {} as Walmart;
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.walmart = {} as Walmart;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ///////////////////////FUNCION IMPRIMIR DTE///////////////////////
  printInvoice($event) {
    $event.preventDefault();
    const line: any = this.activeRow;
    this._printService.printMegaprint(line.pdf);
  }

  ///////////////////////DESCARGAR XML FIRMADO///////////////////////
  downloadFunction($event: Event, name: string, xml: string) {
    // https://variabletecnica.com/2020/09/21/descargar-archivos-con-javascript/
    $event.preventDefault();

    const dataXML = [xml];
    const data = new Blob(dataXML, { type: 'text/xml' });

    const save = document.createElement('a');
    save.download = name;
    save.target = '_blank';
    save.href = URL.createObjectURL(data);

    var eventoClic = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    save.dispatchEvent(eventoClic);
    URL.revokeObjectURL(save.href);
  }

  ///////////////////////FUNCION LIMPIAR MODELO///////////////////////
  async cleanModel() {
    this.file = {};
    this.error = false;
    this.errorCount = 0;
    this.documentList = []
    this.rowsDocument = [];
    this.rowsDocumentError = [];
    this.rowsDocumentCertified = [];

    const company = await this._companyService.getOne(this.data.companyCode);
    this.company = company.records[0];
    console.log(this.company);
  }
}
