<!-- class="sidebar badge-red active" data-color="blue / yellow / red" -->
<app-loading *ngIf="loading"></app-loading>
<div class="sidebar badge-red active" data-color="blue">
    <div class="logo bg-orange">
        <div class="img-logo mx-auto d-block">
            <img src="./assets/img/Clever-blanco-logo-nombre.png"/>
        </div>
    </div>
    <div class="sidebar-wrapper bg-orange-grad">
        <div class="user">
            <div class="photo"><img src="../assets/img/face.jpg" /></div>
            <div class="info">
                <a data-toggle="collapse" href="#collapseExample"
                    (click)="isCollapsed = !isCollapsed; myFunc($event,[])" [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample">
                    <div *ngIf="nameFirstLabel && !nameSecondLabel">
                        <span> {{ nameFirstLabel }} <b class="caret"></b> </span>
                    </div>
                    <div *ngIf="nameFirstLabel && nameSecondLabel">
                        <span> {{ nameFirstLabel }} </span><br>
                        <span> {{ nameSecondLabel }} <b class="caret"></b> </span>
                    </div>
                </a>
                <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
                    <ul class="nav">
                        <li>
                            <a routerLink="/profile">
                                <span class="sidebar-mini-icon">CC</span>
                                <span class="sidebar-normal">Cambiar contraseña</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="logOut()">
                                <span class="sidebar-mini-icon">CS</span>
                                <span class="sidebar-normal">Cerrar sesión</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ul class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
                <!--If is a single link-->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i class="now-ui-icons {{ menuitem.icontype }}"></i>
                    <p>{{ menuitem.title }}</p>
                </a>
                <!--If it have a submenu-->
                <a data-toggle="collapse" href="#{{ menuitem.collapse }}" *ngIf="menuitem.type === 'sub'" (click)="
            myFunc($event, menuitem);
            menuitem.isCollapsing === undefined &&
              (menuitem.isCollapsed = !menuitem.isCollapsed)
          " [attr.aria-expanded]="!menuitem.isCollapsed">
                    <i class="now-ui-icons {{ menuitem.icontype }}"></i>
                    <p>{{ menuitem.title }}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'"
                    [ngbCollapse]="menuitem.isCollapsed">
                    <ul class="nav">
                        <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
                            <a [routerLink]="[menuitem.path, childitem.path]">
                                <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
                                <span class="sidebar-normal">{{ childitem.title }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>