import { Component, OnInit } from '@angular/core';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { Company } from 'src/app/models/company';
import { AccessService } from '../../../services/access.service';
import { CompanyService } from 'src/app/services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [UtilsService, CompanyService, AccessService]
})
export class CompanyComponent implements OnInit {
  // MODELS VARIABLE 
  public company = {} as Company;
  public userCode: number;
  public companyCode: number;
  public accessList: any[] = new Array();
  public isEdit: boolean;
  public isDisabled: boolean;
  public settings = {
    columns: {
      companyName: {
        title: 'Razon Social'
      },
      companyBusinessName: {
        title: 'Nombre Comercial'
      },
      companyTaxDocument: {
        title: 'Nit'
      },
      telefono: {
        title: 'Telefono'
      },
      correo: {
        title: 'Correo'
      },
      direccion: {
        title: 'Direccion'
      },
      companyKey: {
        title: 'Llave'
      },
      companyToken: {
        title: 'Token'
      },
      companyPrefix: {
        title: 'Prefijo'
      },
      companyPhraseCode: {
        title: 'Codigo Fase'
      },
      companyPhraseType: {
        title: 'Tipo Fase'
      },
      companySubdomain: {
        title: 'Dominio'
      },
      companyUrl: {
        title: 'url'
      },
      companyLogo: {
        title: 'Logotipo'
      }
    }
  };
  companyLogoUrl;
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public companyList: Company[] = new Array();

  // VARIABLE FOR SELECT 
  public companyTypeList: any[] = new Array();
  public selectedItemsType = [];
  public companyPlanList: any[] = new Array();
  public selectedItemsPlan = [];
  public dropdownSettings = {};

  constructor(
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    // LOAD VARIABLE
    this.isEdit = false;
    this.isDisabled = false;
    this.company.companyPDFType = 0;
    this.company.companyTaxToPay = 12;
    this.company.companyCertifier = 1;
    this.company.companyInvoiceType = 0;
    this.company.companyInvoicePrint = 0;
    this.companyLogoUrl = '../../../assets/img/placeholder.jpg';

    // LOAD INFORMATION
    this.getAll();
    this.loading = true;

    // SELECT TYPE COMPANY
    this.getCompanyTypeList();
    this.getCompanyPlanList();
    this.getSelectSettingType(this.selectedItemsType);
    this.getSelectSettingPlan(this.selectedItemsPlan);
  }

  ///////////////////////FUNCTION ACCESS///////////////////////
  getPermission(url, roleCode) {
    return this._accessService.getAccessByCompanyRole(url, roleCode).then(
      response => {
        if (response.result == true) {
          this.accessList = response.records;
        }
      },
      error => {
        console.log(error);
      }
    );
  };

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.companyList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSettingType(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsType = [selected] : '';


    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };
  };

  getSelectSettingPlan(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsPlan = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };
  };

  getCompanyTypeList() {
    return this._companyService.getAllCompanyType().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.companyTypeList.push({
              id: e.companyTypeCode,
              itemName: e.companyTypeName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getCompanyPlanList() {
    return this._companyService.getAllCompanyPlan().then(
      response => {
        if (response.result == true) {
          response.records.forEach(e => {
            this.companyPlanList.push({
              id: e.planCode,
              itemName: e.planName
            });
          });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelect(item: any) {
    this.company.companyType = item.id;
  }

  onItemSelectPlan(item: any) {
    this.company.companyPlan = item.id;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL COMPANY
  getAll() {
    this.loading = true;
    return this._companyService.getAll().then(
      response => {
        if (response.result) {
          if(this.companyCode === 1){
            this.companyList = response.records;
          }else{
            this.companyList = response.records.filter(x => x.companyCompanyCreationCode === this.companyCode || x.companyCode === this.companyCode);
          }
          this.temp = this.companyList; 
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE COMPANY
  async viewFunction($event) {
    $event.preventDefault();
    // let temporal = await this._companyService.getOne(this.activeRow.companyCode);
    
    swal.fire({
      title: "Empresa:",
      html:
        '<div class="row">' +
        '<div class="col-8 text-left">' +
        '<b>Razón Social: </b>' + this.activeRow.companyName + '<br>' +
        '<b>Nombre Comercial: </b>' + this.activeRow.companyBusinessName + '<br>' +
        '<b>Tipo de Empresa: </b>' + this.activeRow.companyType + '<br>' +
        '<b>Nit: </b>' + this.activeRow.companyTaxDocument + '<br>' +
        '<b>Página Web : </b>' + this.activeRow.companyUrl + '<br>' +
        '<b>Sub Dominio: </b>' + this.activeRow.companySubdomain + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.companyPhone + '<br>' +
        '<b>Dirección: </b>' + this.activeRow.companyAddress + '<br>' +
        '<b>Correo: </b>' + this.activeRow.companyEmail + '<br>' +
        '<b>Plan: </b>' + this.activeRow.companyPlan + '<hr>' +
        '<b>Llave: </b>' + this.activeRow.companyKey + '<br>' +
        '<b>Token: </b>' + this.activeRow.companyToken + '<br>' +
        '<b>Prefijo: </b>' + this.activeRow.companyPrefix + '<br>' +
        '<b>Código Frase: </b>' + this.activeRow.companyPhraseCode + '<br>' +
        '<b>Tipo Frase: </b>' + this.activeRow.companyPhraseType + '<br>' +
        '<b>Impuesto: </b>' + this.activeRow.companyTaxToPay + "%</div>" +
        "<div class='col-4 mx-auto my-auto'> <img src='" + this.activeRow.companyLogoUrl + "'/></div></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  // INSERT
  async save() {
    this.isDisabled = true;
    this.loading = true;

    this.company.companyStatus = 1;
    this.company.companyDeleted = 0;
    this.company.companyInvoiceType = 0;
    this.company.companyPasswordChange = 1;
    this.company.companyCompanyCreationCode = this.companyCode;
    this.company.companySignInPasswordChange ? this.company.companySignInPasswordChange = 1 : this.company.companySignInPasswordChange = 0;
    this.company.companyPasswordChange ? this.company.companyPasswordChange = 1 : this.company.companyPasswordChange = 0;
    this.company.companyExistenceFlag ? this.company.companyExistenceFlag = 1 : this.company.companyExistenceFlag = 0;
    this.company.companyInvoiceFuel ? this.company.companyInvoiceFuel = 1 : this.company.companyInvoiceFuel = 0;
    this.company.companyCentralized ? this.company.companyCentralized = 1 : this.company.companyCentralized = 0;
    !this.company.companySignInAttempt ? this.company.companySignInAttempt = 3 : '';
    this.company.companyRetention = 0;
    this.company.companyDiscount = this.company.companyDiscount == undefined ? 0 : this.company.companyDiscount;

    //Convertir Archivo a base 64
    if (this.isObjectEmpty(this.state)) {
      this.company.companyLogo = '';
      this.company.companyLogoName = '';
    } else {
      const file = this.state.files.item(0);
      await this.convertBase64(file).then(
        (data: string) => {
          this.company.companyLogoName = this.state.fileNames;
          this.company.companyLogo = data;
        }
      );
    }

    // console.log(this.company);
    this._companyService.insert(this.company).then(
      async (response) => {
        if (response.result == true) {
          this.getAll();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = true;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.isDisabled = false;
      this.loading = false;
    });
  }

  // UPDATE
  async update() {
    this.isDisabled = true;
    this.loading = true;

    //this.company.companyCompanyCreationCode = this.companyCode;
    this.company.companySignInPasswordChange ? this.company.companySignInPasswordChange = 1 : this.company.companySignInPasswordChange = 0;
    this.company.companyPasswordChange ? this.company.companyPasswordChange = 1 : this.company.companyPasswordChange = 0;
    this.company.companyExistenceFlag ? this.company.companyExistenceFlag = 1 : this.company.companyExistenceFlag = 0;
    this.company.companyCentralized ? this.company.companyCentralized = 1 : this.company.companyCentralized = 0;
    this.company.companyInvoiceFuel ? this.company.companyInvoiceFuel = 1 : this.company.companyInvoiceFuel = 0;

    //Convertir Archivo a base 64
    if (this.isObjectEmpty(this.state)) {
 
    } else {
      const file = this.state.files.item(0);
      await this.convertBase64(file).then(
        (data: string) => {
          this.company.companyLogoName = this.state.fileNames;
          this.company.companyLogo = data;
        }
      );
    }

    this._companyService.update(this.company).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.getAll();
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.company.companyCode = this.activeRow.companyCode;
    this.company.companyStatus = 0;
    this.company.companyDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "La empresa se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  async editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    if (this.activeRow.companyLogo != '') {
      this.company.companyLogo = this.activeRow.companyLogo;
      this.companyLogoUrl = this.activeRow.companyLogoUrl;
    } else {
      this.company.companyLogoName = '';
      this.companyLogoUrl = "../../../assets/img/placeholder.jpg"
    }

    this.company.companyCode = this.activeRow.companyCode;
    this.company.companyName = this.activeRow.companyName;
    this.company.companyBusinessName = this.activeRow.companyBusinessName;
    this.company.companyTaxDocument = this.activeRow.companyTaxDocument;
    this.company.companyUrl = this.activeRow.companyUrl;
    this.company.companySubdomain = this.activeRow.companySubdomain;
    this.company.companyPhone = this.activeRow.companyPhone;
    this.company.companyAddress = this.activeRow.companyAddress;
    this.company.companyEmail = this.activeRow.companyEmail;
    this.company.companySignInAttempt = this.activeRow.companySignInAttempt;
    this.company.companyPrefix = this.activeRow.companyPrefix;
    this.company.companyKey = this.activeRow.companyKey;
    this.company.companyToken = this.activeRow.companyToken;
    this.company.companyPhraseCode = this.activeRow.companyPhraseCode;
    this.company.companyPhraseType = this.activeRow.companyPhraseType;
    this.company.companyTaxToPay = this.activeRow.companyTaxToPay;
    this.company.companyStatus = this.activeRow.companyStatus;
    this.company.companyDeleted = this.activeRow.companyDeleted;
    this.company.companyCertifier = this.activeRow.companyCertifier;
    this.company.companyInvoiceType = this.activeRow.companyInvoiceType;
    this.company.companyInvoicePrint = this.activeRow.companyInvoicePrint;
    this.company.companyDiscount = this.activeRow.companyDiscount;
    this.company.companyPDFType = this.activeRow.companyPDFType;

    this.activeRow.companyInvoiceFuel == 1 ? this.company.companyInvoiceFuel = true : this.company.companyInvoiceFuel = false;
    this.activeRow.companyCentralized == 1 ? this.company.companyCentralized = true : this.company.companyCentralized = false;
    this.activeRow.companyExistenceFlag == 1 ? this.company.companyExistenceFlag = true : this.company.companyExistenceFlag = false;
    this.activeRow.companyPasswordChange == 1 ? this.company.companyPasswordChange = true : this.company.companyPasswordChange = false;
    this.activeRow.companySignInPasswordChange == 1 ? this.company.companySignInPasswordChange = true : this.company.companySignInPasswordChange = false;

    this.company.companyType = this.activeRow.companyType;
    this.company.companyPlan = this.activeRow.companyPlan;
    const _selectedItemType = this.companyTypeList.find(x => x.id == this.activeRow.companyType);
    const _selectedItemPlan = this.companyPlanList.find(x => x.id == this.activeRow.companyPlan);
    this.getSelectSettingType(_selectedItemType);
    this.getSelectSettingPlan(_selectedItemPlan);
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    this.company = {} as Company;
    this.selectedItemsType = null;
    this.selectedItemsPlan = null;
    this.state = {};

    this.company.companyPDFType = 0;
    this.company.companyCertifier = 1;
    this.company.companyTaxToPay = 12;
    this.company.companyInvoiceType = 0;
    this.company.companyInvoicePrint = 0;

    this.companyLogoUrl = '../../../assets/img/placeholder.jpg';
  }

  // FUNCTION FOR IMAGENS
  public state: any = {};
  addFile(e) {
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;
  }

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //verificar si esta vacio el state
  isObjectEmpty = function (state) {
    return Object.keys(state).length === 0;
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-empresa.xlsx');
  }
}
