import { Component, OnInit } from '@angular/core';
import { Data, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { XmlService } from 'src/app/services/xml.service';

interface Filter {
  begDate: string,
  endDate: string,
  company: number
}

@Component({
  selector: 'app-report-sylvania',
  templateUrl: './report-sylvania.component.html',
  styleUrls: ['../../../app.component.scss'],
  providers: [UtilsService, XmlService]
})
export class ReportSylvaniaComponent implements OnInit {
  public filter: Filter = {} as Filter;
  public data: Data = {} as Data;
  public typeReport: string = '';
  public nameReport: string = '';
  public loading: boolean = false;
  public disabled: boolean = false;

  // variables data table
  public activeRow: any = {};
  public selected: any[] = [];
  public entries: number = 10;
  public rows: any[] = new Array();
  public rowsSale: any[] = new Array();
  public rowsBook: any[] = new Array();
  public rowsList: any[] = new Array();

  constructor(
    public router: Router,
    private _xmlService: XmlService,
    private _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    const urlTree = this.router.parseUrl(this.router.url);
    const location = urlTree.root.children.primary.segments[0].path;
    switch (location) {
      case 'report-sale':
        this.nameReport = 'Reporte de ventas Sylvania';
        this.typeReport = 'SALE';
        break;
      case 'report-book':
        this.nameReport = 'Reporte Asiste Libros';
        this.typeReport = 'BOOK';
        break;
    }

    const _data = JSON.parse(localStorage.getItem('data'));
    this.data.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.data.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.data.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;

    this.filter.company = this.data.companyCode;
    this.filter.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.filter.endDate = this._utilsService.dateTime('yyy-MM-dd');
    this.loading = false;
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.rows = this.rowsList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  getAll() {
    if (this.filter.begDate != '' && this.filter.endDate != '') {
      this.loading = true;
      this.disabled = true;

      if (this.typeReport == 'SALE') {
        this._xmlService.getAllSale(this.filter.begDate, this.filter.endDate, this.filter.company).then(
          (response) => {
            if (response.result == true) {
              this.rows = response.records;
              this.rowsList = this.rows;
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            console.log(error);
            this._utilsService.showNotification(2, error);
          }
        ).finally(() => {
          this.loading = false;
          this.disabled = false;
        });
      } else {
        this._xmlService.getAllBook(this.filter.begDate, this.filter.endDate, this.filter.company).then(
          (response) => {
            if (response.result == true) {
              this.rows = response.records;
              this.rowsList = this.rows;
              this._utilsService.showNotification(1, response.message);
            } else {
              this._utilsService.showNotification(3, response.errorMessage);
            }
          },
          error => {
            console.log(error);
            this._utilsService.showNotification(2, error);
          }
        ).finally(() => {
          this.loading = false;
          this.disabled = false;
        });
      }

    } else {
      this.disabled = false;
      this._utilsService.showNotification(2, "Seleccionar un rango de fechas");
    }
  }

  async downloadToExcel() {
    if (this.typeReport === 'SALE') { 
      const list = await this.rows.map(function (x) {
        const result = {
          IDSERIE: x.serie ? x.serie : '',
          NODOCUMENTO: x.numero ? x.numero : '',
          MONEDA: x.moneda ? x.moneda : '',
          NITCONTRIBUYENTE: x.nit_Cliente ? x.nit_Cliente : '',
          NOMBRECONTRIBUYENTE: x.nombre_Cliente ? x.nombre_Cliente : '',
          DIRECCIONCONTRIBUYENTE: x.direccion_Cliente ? x.direccion_Cliente : '',
          FECHAEMISION: x.fecha_Emision ? x.fecha_Emision : '',
          EMPRESA: x.empresa ? x.empresa : '',
          CAJA: x.caja ? x.caja : '',
          SUCURSAL: x.sucursal ? x.sucursal : '',
          USUARIO: x.usuario ? x.usuario : '',
          GENERACION: x.generacion ? x.generacion : '',
          IVA: x.iva ? parseFloat(x.iva) : '',
          TOTAL: x.total ? parseFloat(x.total) : '',
          EXENTO: x.exento ? parseFloat(x.exento) : '',
          VALORNETO: x.valor_Neto ? parseFloat(x.valor_Neto) : '',
          TASACAMBIO: x.tasa_Cambio ? parseFloat(x.tasa_Cambio) : '',
          RESOLUCION: x.resolucion ? x.resolucion : '',
          RESOLUCIONFACTURA: x.resolucion_Factura ? x.resolucion_Factura : '',
          FECHAFACTURA: x.fecha_Factura ? x.fecha_Factura : '',
          CONCEPTO: x.concepto ? x.concepto : '',
          FEL_SERIE: x.serie_Fel ? x.serie_Fel : '',
          FEL_NUMERO: x.numero_Fel ? x.numero_Fel : '',
          FEL_GUI: x.gui_Fel ? x.gui_Fel : '',
          FEL_FECHA_ENVIO: x.fecha_Envio_Fel ? x.fecha_Envio_Fel : '',
          FEL_FECHA_ANULACION: x.fecha_Anulacion_Fel ? x.fecha_Anulacion_Fel : '',
          FEL_GUI_ANULACION: x.gui_Anulacion_Fel ? x.gui_Anulacion_Fel : '',
          FEL_SERIE_ANULACION: x.serie_Anulacion_Fel ? x.serie_Anulacion_Fel : '',
          FEL_NUMERO_ANULACION: x.numero_Anulacion_Fel ? x.numero_Anulacion_Fel : '',
          FEL_FECHA_NC: x.fecha_NC_Fel ? x.fecha_NC_Fel : '',
          FEL_GUI_NC: x.gui_NC_Fel ? x.gui_NC_Fel : '',
          FEL_SERIE_NC: x.serie_NC_Fel ? x.serie_NC_Fel : '',
          FEL_NUMERO_NC: x.numero_NC_Fel ? x.numero_NC_Fel : '',
          FEL_ERROR: x.error_Fel ? x.error_Fel : '',
        };
        return result;
      });
      this._utilsService.downloadToExcel(list, 'Ventas', `Ventas sylvania - ${this.filter.endDate} - ${this.filter.begDate}`);
    };

    if (this.typeReport === 'BOOK') {
      const list = await this.rows.map(function (x) {
        const result = {
          'DOCUMENTO': x.documento,
          'COMPRAS/VENTAS': x.compra_Venta,
          'SERIE DEL DOCUMENTO': x.serie_Documento,
          'NUMERO DEL DOCUMENTO': x.numero_Documento,
          'FECHA DEL DOCUMENTO': x.fecha_Documento,
          'NIT DEL CLIENTE / PROVEEDOR': x.nit_Cliente,
          'NOMBRE DEL CLIENTE/PROVEEDOR': x.nombre_Cliente,
          'TIPO DE TRANSACCION': x.tipoTransaccion,
          'TIPO DE OPERACION (BIEN O SERVICIO)': x.tipoOperacion,
          'ESTADO DEL DOCUMENTO': x.estado,
          'NO. DE ORDEN DE LA CEDULA, DPI O PASAPORTE': x.numero_Orden,
          'NO. DE REGISTRO DE LA CEDULA, DPI O PASAPORTE': x.numero_Registro,
          'TIPO DOCUMENTO DE OPERACION': x.tipo_Documento_Operacion,
          'NUMERO DEL DOCUMENTO DE OPERACION': x.numero_Documento_Operacion,
          'TOTAL VALOR GRAVADO DEL DOCUMENTO, BIENES OPERACION LOCAL': parseFloat(x.bienes_Operacion_Local_TVGD),
          'TOTAL VALOR GRAVADO DEL DOCUMENTO, BIENES OPERACION DEL EXTERIOR': parseFloat(x.bienes_Operacion_Exterior_TVGD),
          'TOTAL VALOR GRAVADO DEL DOCUMENTO SERVICIOS OPERACION LOCAL': parseFloat(x.servicios_Operacion_Local_TVGD),
          'TOTAL VALOR GRAVADO DEL DOCUMENTO SERVICIOS OPERACION DEL USO EXTERIOR': parseFloat(x.servicios_Operacion_Exterior_TVGD),
          'TOTAL VALOR EXENTO DEL DOCUMENTO, BIENES OPERACION LOCAL': parseFloat(x.bienes_Operacion_Local_TVED),
          'TOTAL VALOR EXENTO DEL DOCUMENTO, BIENES OPERACION DEL  EXTERIOR': parseFloat(x.bienes_Operacion_Exterior_TVED),
          'TOTAL VALOR EXENTO DEL DOCUMENTO, SERVICIOS OPERACION LOCAL': parseFloat(x.servicios_Operacion_Local_TVED),
          'TOTAL VALOR EXENTO DEL DOCUMENTO, SERVICIOS OPERACION DEL EXTERIOR': x.servicios_Operacion_Exterior_TVED,
          'TIPO DE CONSTANCIA': x.tipo_Constancia,
          'NUMERO DE LA CONSTANCIA DE EXENCION/ADQUISICION DE INSUMOS/RETENCION DEL IVA': x.numero_Constancia,
          'TOTAL VALOR DEL DOCUMENTO': parseFloat(x.total),
          IVA: parseFloat(x.iva),
        };
        return result;
      });
      this._utilsService.downloadToExcel(list, 'Asiste libros', `Asiste libros - ${this.filter.endDate} - ${this.filter.begDate}`);
    };
  }
}
