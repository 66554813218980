import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PayRegister } from '../models/pay-register';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccountingService {
    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET PAYMENT METHOD
    getPaymentMethod(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-paymentMethod', { headers: headers }).toPromise();
    }

    // GET ACCOUNT TYPE
    getAccountType(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-accountType', { headers: headers }).toPromise();
    }

    ///////////////////DUDAS
    // GET ALL PAYMENT INVOICE (SALES) PENDING
    getAllPaymentInvoice(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-invoice/report/payment', params, { headers: headers }).toPromise();
    }

    // GET ALL PAYMENT PURCHASE PENDING
    getAllPaymentPurchase(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-purchase/payment', params, { headers: headers }).toPromise();
    }

    // GET ALL PAYMENT REGISTER BY INVOICE CODE
    getAllPaymentByStore(type, storeCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-paymentRegister/one/' + type + '/' + storeCode, { headers: headers }).toPromise();
    }

    // GET ALL PAYMENT REGISTER BY INVOICE / PURCHASE CODE
    getAllPayRegister(json): Promise<any> {
        let params = JSON.stringify(json);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-paymentRegister/detail/', params, { headers: headers }).toPromise();
    }

    // INSERT PAYMENT REGISTER
    insertPayRegister(payRegister: PayRegister): Promise<any> {
        let params = JSON.stringify(payRegister);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-paymentRegister', params, { headers: headers }).toPromise();
    }

    ///// BALANCE
    // GET ALL BY DATE
    getPaymentBalanceSheet(data): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-balanceSheetRegister/payment', params, { headers: headers }).toPromise();
    }

    getBalanceSheet(data): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-balanceSheetRegister/all', params, { headers: headers }).toPromise();
    }

    // GET ALL PENDING
    getPendingBalanceSheet(data): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-balanceSheetRegister/diff ', params, { headers: headers }).toPromise();
    }

    // INSERT
    insertBalanceSheet(data): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-balanceSheetRegister', params, { headers: headers }).toPromise();
    }

    // UPDATE
    updateBalanceSheet(data): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-balanceSheetRegister', params, { headers: headers }).toPromise();
    }

    ////DIARY
    // GET ALL DAIRY REGISTER BY COMPANY/SUCURSAL 
    getDairyRegister(data, type): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let api = type == 1 ? 'erprest-dairyRegisterStore/date' : 'erprest-dairyRegisterCompany/date'; 
        return this._http.post(this.url + api, params, { headers: headers }).toPromise();
    }

    // INSERT DAIRY REGISTER AS COMPANY/SUCURSAL
    insertDairyRegister(data, type): Promise<any> {
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let api = type == 1 ? 'erprest-dairyRegisterStore' : 'erprest-dairyRegisterCompany'; 
        return this._http.post(this.url + api, params, { headers: headers }).toPromise();
    }

     // GET ALL RETENTION
     getAllRetention(begDate, endDate, storeCode): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-retention/date/' + begDate + '/' + endDate + '/' + storeCode, { headers: headers }).toPromise();
    }


}