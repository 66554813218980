<app-loading *ngIf="loading"></app-loading>
<div class="wrapper wrapper-full-page">
    <app-sidebar *ngIf="isLogged == true"></app-sidebar>
    <div [class]="(!isLoginPage && isLogged) ? 'main-panel' : 'full-page section-image'" filter-color="black">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div [class]="isLogged == true ? '.footer-login' : ''" > <!--bg-white-->
            <app-footer ></app-footer>
        </div>
    </div>
</div>
