import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { AccessService } from 'src/app/services/access.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-report-receipt-company',
  templateUrl: './report-receipt-company.component.html',
  styleUrls: ['./report-receipt-company.component.scss'],
  providers: [UtilsService, InvoiceService, AccessService]
})
export class ReportReceiptCompanyComponent implements OnInit {
  loading;
  // Variables auxiliares
  public isBeginning: boolean;
  public isReprint: boolean;
  public isDisabled: boolean;
  public userCommerceCode: number;
  public begDate: string;
  public endDate: string;
  public reasonForCancellation;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public settings = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      factura: {
        title: 'Serie - No. Factura FEL'
      },
      letra: {
        title: 'Letra'
      },
      correlativo: {
        title: 'Correlativo',
      },
      nit: {
        title: 'Nit'
      },
      cliente: {
        title: 'Cliente'
      },
      cantidad: {
        title: 'Cantidad',
        type: 'number'
      },
      descripcion: {
        title: 'Descripcion'
      },
      precio: {
        title: 'Precio',
        type: 'number'
      },
      descuento: {
        title: 'Descuento',
        type: 'number'
      },
      total: {
        title: 'Total',
        type: 'number'
      },
      pagado: {
        title: 'Pagado'
      },
      costo: {
        title: 'Costo',
        type: 'number'
      },
      estado: {
        title: 'Estado'
      },
      usuario: {
        title: 'Usuario'
      }
    }
  };

  public settingsPayment = {
    columns: {
      fecha: {
        title: 'Fecha'
      },
      factura: {
        title: 'Serie - No. Factura FEL'
      },
      letra: {
        title: 'Letra'
      },
      correlativo: {
        title: 'Correlativo',
      },
      nit: {
        title: 'Nit'
      },
      cliente: {
        title: 'Cliente'
      },      
      pagado: {
        title: 'Forma de pago'
      },
      montopagado: {
        title: 'Monto Pagado'
      },
      autorizacion: {
        title: 'Autorización'
      },
      estado: {
        title: 'Estado'
      },
      usuario: {
        title: 'Usuario'
      }
    }
  };

  totalTotal: number;

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp: any[] = new Array();
  public invoiceList: any[] = new Array();
  public tipoR: number;

  constructor(
    private _utilsService: UtilsService,
    private _accessService: AccessService,
    private _invoiceService: InvoiceService
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    let _data = JSON.parse(localStorage.getItem('data'));
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.begDate = this._utilsService.dateTime('yyy-MM-dd');
    this.endDate = this._utilsService.dateTime('yyy-MM-dd');

    this.totalTotal = 0;
    this.viewBeginning();
    this.tipoR = 1;
  }

  onChangeType(event) {
    this.tipoR = Number(event);
    console.log(this.tipoR);
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isReprint = false;
  }

  viewReport() {
    this.isBeginning = false;
    this.isReprint = true;
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.invoiceList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////
  //Ver todas las factura
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "company": this.companyCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this.temp = [];
      this.totalTotal = 0;
      this.invoiceList = [];

      this._invoiceService.getReportReceiptAllCompany(json).then(
        async (response) => {
          if (response.result == true) {
            console.log(response.records);
            response.records.forEach(e => {

              e.detailpayment = JSON.parse(e.detailpayment);              

              if(this.tipoR == 1){

                let formapago = "";
                e.detailpayment.forEach((p) => {
                  formapago = formapago + ' Met. ' + p.metodo + ' - Mont. ' + parseFloat(p.monto).toFixed(2) + ' - Aut.' + p.autorizacion + ';'                
                });
                console.log(formapago);

                e.detail.forEach((d) => {
                  this.invoiceList.push({
                    sucursal: e.storeName,
                    letra: e.receiptInternSerial,
                    correlativo: e.receiptInternNumber,
                    fecha: e.fecha,
                    factura: e.receiptInternSerial + ' - ' + e.receiptInternNumber,
                    nit: e.clientTaxDocument,
                    cliente: e.clientName,
                    usuario: e.usuario,
                    estado: e.receiptStatus == 0 ? 'INACTIVO' : 'ACTIVO',
                    total: d.total,
                    pagado: formapago,
                    costo: d.costo,
                    cantidad: d.cantidad,
                    descuento: d.descuento,
                    precio: d.precioUnitario,
                    descripcion: d.descripcion,
                    montopagado: d.total,
                    vendedor: e.vendedor,
                    origen: e.origen
                  });
                  this.totalTotal += parseFloat(d.total);
                });

              }
              else if(this.tipoR == 2){

                let total = 0;

                e.detailpayment.forEach((p) => {
                  this.invoiceList.push({
                    sucursal: e.storeName,
                    letra: e.receiptInternSerial,
                    correlativo: e.receiptInternNumber,
                    fecha: e.fecha,
                    factura: e.receiptInternSerial + ' - ' + e.receiptInternNumber,
                    nit: e.clientTaxDocument,
                    cliente: e.clientName,
                    usuario: e.usuario,
                    estado: e.receiptStatus == 0 ? 'INACTIVO' : 'ACTIVO',
                    pagado: p.metodo,                    
                    montopagado: parseFloat(p.monto).toFixed(2),
                    autorizacion: p.autorizacion,
                    vendedor: e.vendedor,
                  });

                  total = parseFloat( Number(p.monto).toFixed(2))

                  this.totalTotal += total;
                });

              }
              
            });
            await  this._utilsService.orderDate(this.invoiceList, 'fecha', '/');      
            this.temp = this.invoiceList;

            //console.log(this.invoiceList);
            this.isDisabled = false;
            this.isReprint = true;
            this._utilsService.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      // Mostrar notificación
      this._utilsService.showNotification(2, "Ingresar Datos");
    }
  }

  summaryForTotal(cells: string[]) {
    console.log("hola:", cells)
    const total = cells.filter(cell => cell === 'total').length;
    return `total: ${total}`;
  }


  ///////////////////////DESCARGAR EXCEL///////////////////////
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    if(this.tipoR == 1){
      checkList.forEach(element => {
        let json = {};
        Object.keys(this.settings.columns).forEach((item, index, arr) => {
          if (this.settings.columns[item].type === "number") {
            json[this.settings.columns[item].title] = parseFloat(element[item]);
          } else {
            json[this.settings.columns[item].title] = element[item];
          }
        });
        exportFile.push(json);
      });
    }
    else if(this.tipoR == 2){
      checkList.forEach(element => {
        let json = {};
        Object.keys(this.settingsPayment.columns).forEach((item, index, arr) => {
          if (this.settingsPayment.columns[item].type === "number") {
            json[this.settingsPayment.columns[item].title] = parseFloat(element[item]);
          } else {
            json[this.settingsPayment.columns[item].title] = element[item];
          }
        });
        exportFile.push(json);
      });
    }
    
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-facturas.xlsx');
  }
}
