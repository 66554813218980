import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})

export class FooterComponent implements OnInit {

  public version: string;

  constructor() { }

  ngOnInit() { 
    this.version = "Ver. 17042024";
  }
}

