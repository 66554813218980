<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div class="col-md-1 ma-g">
            <div class="row">
                <div class="col-md-12">
                    <a class="btn btn-round btn-info btn-icon btn-back remove"
                        (click)="viewBeginning()">
                        <i class="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                </div>
            </div>
        </div>
        <div>
            <a class="a-home btn-gen" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="a-home btn-gen" routerLink="/billing">Facturación Restaurantes</a>
        </div>
    </div>
</div>

<div class="main-content">
    <!-- MESAS -->
    <div class="row justify-content-center " *ngIf="ordersLocked && !isPrintT && !isPrintOrder">
        <div class="col-md-12">
            <div class="jumbotron cardsdetalle">
                <div class="col-sm-12"><b><i class="now-ui-icons shopping_shop"></i> Mesas de {{storeName}}:</b></div>
                <hr>
                
                <div class="media" *ngFor="let table of tablesList">
                    <a role="button" (click)="asignarMesa(table.restaurantTablesNumber)">
                        <img src="../../../../../assets/img/mesa.png" class="mr-3" alt="..." style="width: 5rem;">
                        <div class="media-body">
                            <h5 class="mt-0 btn-gen {{table.class}}">Mesa {{table.restaurantTablesNumber}}</h5>                            
                        </div>
                    </a>
                </div>
            </div>            
        </div>        
    </div>
    <!-- FIN MESAS -->

    <div class="row justify-content-center " *ngIf="isBilling && !PaymentStatus && !ordersLocked && !isPrintT && !isPrintOrder">
        <div class="col-sm-12 btn-gen">Sucursal: <b>{{storeName}} - MESA No. {{mesaOrden}}</b></div>
    </div>

    <!-- PRODUCTOS -->
    <div class="row justify-content-center " *ngIf="isBilling && !PaymentStatus && !ordersLocked && !isPrintT && !isPrintOrder">
        
        <div class="col-md-4">
            <div class="jumbotron cardsdetalle">
                <h3 class="display-5">Detalle de la Orden<!-- <a class="btn btn-sm btn-danger btn-clear order" role="button" (click)="cleanOrder()">Limpiar orden completa</a> --></h3> 
                <div class="row rowdetalle">
                    <div class="alert alert-info detalletag" role="alert" *ngFor="let det of invoiceLine, let i=index">                    
                        <p class="descdet">
                            <button class="btn btn-danger btn-sm btn-clear product" (click)="deleteLine(det.itemCode)" [disabled]="isDisabled" ngbTooltip="Eliminar" placement="top">
                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                <!-- Eliminar producto -->
                            </button>
                            {{i+1}}. 
                            {{det.description}}
                        </p>
                        <span class="badge badge-warning btn-gen">Cant.: {{det.quantity}}</span>
                        <span class="badge badge-warning btn-gen">P/U: {{det.unitPrice}}</span>
                        <!-- <span class="badge badge-warning btn-gen">Desc.: {{det.discount}}</span> -->
                        <span class="badge badge-warning btn-gen">Tot.: {{det.total}}</span>
                        <span class="badge badge-warning btn-gen">IVA: {{det.taxAmount}}</span>                        
                    </div>
                </div>
                
                <a class="btn btn-warning btn-block btn-gen" role="button" (click)="saveOrder()"><i class="now-ui-icons files_paper"></i> Guardar Orden</a>
                <a class="btn btn-warning btn-block btn-gen" role="button" (click)="paymentUnlock()"><i class="now-ui-icons business_money-coins"></i> Pagar</a>
                <a class="btn btn-warning btn-block btn-gen" role="button" (click)="printOrder()"><i class="now-ui-icons files_paper"></i> Imprimir Orden</a>
                <a class="btn btn-danger btn-block btn-clear order" role="button" (click)="cleanOrder()"><i class="now-ui-icons loader_refresh"></i> Limpiar Orden</a>                

                <div class="row">
                    <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;" *ngIf="companyCode == 24">
                        <small *ngIf="invoiceHeader.invoiceIDP > 0" style="font-weight: bold; font-size: 20px;">IDP
                            Q{{invoiceHeader.invoiceIDP | number:'1.2-2'}} &nbsp;&nbsp;</small>
                        Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                        {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                    </div>
                    <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;" *ngIf="companyCode != 24">
                        <small *ngIf="invoiceHeader.invoiceIDP > 0" style="font-weight: bold; font-size: 20px;">IDP
                            Q{{invoiceHeader.invoiceIDP | number:'1.2-2'}} &nbsp;&nbsp;</small>
                        Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                        {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                    </div>
                </div>
            </div>            
        </div>

        <div class="col-md-8">
            <div class="card mb-3 cardsproductos">
                <div class="row" [hidden]="true">
                    <div class="col-md-6">
                        <label>Vendedor(a)</label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                            [settings]="dropdownSettingsSalesP" [data]="SalesPList" [(ngModel)]="selectedItemsSalesP"
                            (onSelect)="onItemSelectSalesP($event)" [disabled]='isEdit'>
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                <button type="button" class="btn btn-warning btn-lg btn-gen" (click)="viewCategory()" *ngIf="viewproduct">Regresar a categorías</button>
                <button type="button" class="btn btn-warning btn-lg btn-gen" (click)="viewCategory()" *ngIf="!viewproduct">Categorías de productos</button>
                <button type="button" class="btn btn-warning btn-lg btn-gen" (click)="viewPropinas()">Propina</button>

                <div class="row anyClass" *ngIf="viewProp">                            
                    <div class="products-card">
                        <div class="card card-product text-center cardpropina">
                            <a class="btn btn-round btn-danger btn-icon btn-back remove"
                                (click)="viewCategory()">
                                <i class="now-ui-icons ui-1_simple-remove"></i>
                            </a>
                            <div class="card-body cardbodyproduct">
                                <h5 class="card-title">Propina {{moneda}}</h5>
                            </div>
                            <div class="form-group">
                                <input type="number" class="form-control" [(ngModel)]='Propina' (keyup)="calculateChangeMoney($event)" placeholder="Monto" />
                            </div>
                            <!-- <div class="card-body cardbodyproduct">
                                <h5 class="card-title">{{card.productName}}</h5>
                            </div> -->
                            <div class="card-footer mt-auto">
                                <button class="btn btn-sale btn-warning" (click)="savePropina()">AGREGAR</button>
                            </div>                                    
                        </div>
                    </div>
                </div>

                <div class="row anyClass" *ngIf="!viewproduct && !viewAdds && !viewProp">                            
                    <div class="products-card" *ngFor="let card of productList">
                        <div class="card card-product text-center cardproduct">
                            <img src="https://storage.googleapis.com/restaurant-clever/category/{{card.productCode}}.png" class="card-img-top imgproduct" alt="SIN IMAGEN" height="200rem" onerror="this.onerror=null;this.src='https\://storage.googleapis.com/nail-center/Imagenes/no-image.png';"> <!--https\://storage.googleapis.com/restaurant-clever/images/no-image.png-->
                            <div class="card-body cardbodyproduct">
                                <h5 class="card-title">{{card.productName}}</h5>
                            </div>
                            <div class="card-footer mt-auto">
                                <button class="btn btn-sale btn-warning" (click)="getCategory(card.productCode)">VER</button>
                            </div>                                    
                        </div>
                    </div>
                </div>

                <div class="row anyClass" *ngIf="viewproduct && !viewAdds && !viewProp">                            
                    <div class="column" *ngFor="let card of _categoryProductList">
                        <div class="card card-product text-center cardproduct">
                            <img src="https://storage.googleapis.com/restaurant-clever/images/{{card.inventoryBusinessCode}}.png" class="card-img-top" alt="SIN IMAGEN" height="150rem" onerror="this.onerror=null;this.src='https\://storage.googleapis.com/nail-center/Imagenes/no-image.png';">
                            <div class="card-body cardbodyproduct">
                                <h5 class="card-title">{{card.inventoryName}}</h5>
                                <p class="card-text">{{card.inventoryDescription}}</p>
                            </div>
                            <div class="card-footer mt-auto">
                                <!-- <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)">Q{{card.inventoryPrice}}</button> -->
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 1">Q{{card.inventoryPrice | number:'1.2-2'}}</button>
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 2">Q{{card.inventoryMinPrice | number:'1.2-2'}}</button>
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 3">Q{{card.inventoryPriceDiscount | number:'1.2-2'}}</button>
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 4">Q{{card.inventorySalePrice | number:'1.2-2'}}</button>
                            </div>                                    
                        </div>
                    </div>
                </div>

                <h5 *ngIf="!viewproduct && viewAdds && !viewProp">
                    ADICIONALES
                    <a class="btn btn-round btn-danger btn-icon btn-back remove"
                        (click)="showProducts()">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </a>
                </h5>
                <div class="row anyClass" *ngIf="!viewproduct && viewAdds && !viewProp">                            
                    <div class="column" *ngFor="let card of _addsProductList">
                        <div class="card card-product text-center cardproduct">
                            <img src="https://storage.googleapis.com/restaurant-clever/images/{{card.inventoryBusinessCode}}.png" class="card-img-top imgproduct" alt="SIN IMAGEN" height="200rem" onerror="this.src='https://storage.googleapis.com/nail-center/Imagenes/no-image.png';">
                            <div class="card-body cardbodyproduct">                                        
                                <h5 class="card-title">{{card.inventoryName}}</h5>
                                <p class="card-text">{{card.inventoryDescription}}</p>
                            </div>
                            <div class="card-footer mt-auto">
                                <!-- <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)">Q{{card.inventoryPrice}}.00</button> -->
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 1">Q{{card.inventoryPrice | number:'1.2-2'}}</button>
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 2">Q{{card.inventoryMinPrice | number:'1.2-2'}}</button>
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 3">Q{{card.inventoryPriceDiscount | number:'1.2-2'}}</button>
                                <button class="btn btn-sale btn-warning" (click)="addFood(card.inventoryBusinessCode)" *ngIf="card.inventoryPriceDefault == 4">Q{{card.inventorySalePrice | number:'1.2-2'}}</button>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Productos -->

    <div class="row justify-content-center " *ngIf="isBilling && PaymentStatus && !isPrintT && !isPrintOrder"></div>
    <br>

    <!-- BUSCAR NIT -->
        <div class="col-md-12 row justify-content-center mt-3" *ngIf="isBilling && isMethodPay && PaymentStatus && !isPrintT && !isPrintOrder && showInvoiceInfo">
            <div class="card">
                <div class="card-body">
                    
                    <button class="btn btn-warning btn-sale text-center" style="width: 30%;" (click)="backToPaymentMethods()"> <i class="now-ui-icons arrows-1_minimal-left"></i> MODIFICAR FORMAS DE PAGO</button>
                    
                    <!-- Cantidad de cuentas -->
                    <!-- <div class="row mb-2">
                        <div class="col-6 text-center mt-3">
                            <button class="btn btn-warning btn-sale" style="width: 100%;" (click)="divideInChecks()" [disabled]="divideInChecksEnabled">Separar en varias cuenta</button>
                        </div>
                        <div class="col-6">
                            <label>Seleccione la cantidad cuentas</label>
                            <select id="checksQuantityDropdown" class="form-control form-control-sm form-group btn-sale" [ngModel]="checkQuantitySelected"
                                (ngModelChange)="onChecksQuantityChange($event)" [disabled]="!divideInChecksEnabled">
                                <option value=1>1</option>
                            </select>
                        </div>
                    </div> -->

                    <div class="mt-3"><b>Información de Facturación</b></div>
                    <div class="row">
                        <div class="col-md-4 ma-g">
                            <label>Tipo de Identificación</label>
                            <div class="form-group">
                                <select class="form-control form-control-sm selectblack" [ngModel]="client.clientType" (ngModelChange)="onChangeTypeClient($event)">
                                    <option [value]="0">NIT</option>
                                    <option [value]="1">CUI</option>
                                    <option [value]="2">Pasaporte</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4 ma-g">
                            <label>No. Identificación</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument" (keyup.enter)="buscarNITLocal($event, client.clientTaxDocument)" placeholder="*Escribe un NIT/CUI/Pasaporte sin espacios o guiones" minlength="8" />
                            </div>
                        </div>
                        <div class="col-md-4 ma-g">
                            <label>Nombre Receptor</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientName" [toggleable]="true" [disabled]="disabledEXT" placeholder="*Escribe el nombre completo del receptor" minlength="8" />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-6 text-center">
                            <button class="btn btn-warning btn-sale" (click)="buscarNITLocal($event, client.clientTaxDocument)" [disabled]="isDisabled" style="width: 100%;"><i class="now-ui-icons ui-1_zoom-bold"></i> Buscar</button>
                        </div>
                        <div class="col-6 text-center">
                            <button class="btn btn-warning btn-sale" style="width: 100%;" (click)="buscarNITLocal($event, 'CF')" [disabled]="isDisabled">Consumidor Final (CF)</button>
                        </div>
                    </div>                    

                    <hr>
                    
                    <div class="row">
                        <div class="col-md-11 ma-g">
                            <div class="row">
                                <div class="col-md-2 ma-g">
                                    <label>Nit/CUI/EXT</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g"
                                            [(ngModel)]="client.clientTaxDocument" disabled />
                                    </div>
                                </div>
                                <div class="col-md-4 ma-g">
                                    <label>Nombre</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g" [(ngModel)]="client.clientName" />
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>E-mail</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g" [(ngModel)]="client.clientEmail" />
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>Dirección</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g"
                                            [(ngModel)]="client.clientAddress" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-sm text-lighter"
                                [(ngModel)]="invoiceHeader.invoiceDate" (change)="validateDate($event)" />
                        </div>
                        <div class="col-md-2">
                            <label>Serie</label>
                            <select class="form-control form-control-sm text-lighter" [ngModel]="invoiceHeader.invoiceInternSerial"
                                (ngModelChange)="onChangeInternSerial($event)">
                                <option value=A>A</option>
                                <option value=B>B</option>
                                <option value=C>C</option>
                                <option value=D>D</option>
                                <option value=E>E</option>
                                <option value=F>F</option>
                                <option value=G>G</option>
                                <option value=H>H</option>
                                <option value=I>I</option>
                                <option value=J>J</option>
                                <option value=K>K</option>
                                <option value=L>L</option>
                                <option value=M>M</option>
                                <option value=N>N</option>
                                <option value=L>L</option>
                                <option value=O>O</option>
                                <option value=P>P</option>
                                <option value=Q>Q</option>
                                <option value=R>R</option>
                                <option value=S>S</option>
                                <option value=T>T</option>
                                <option value=U>U</option>
                                <option value=V>V</option>
                                <option value=W>W</option>
                                <option value=X>X</option>
                                <option value=Y>Y</option>
                                <option value=Z>Z</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Moneda</label>
                            <select class="form-control form-control-sm text-lighter" [ngModel]="invoiceHeader.invoiceCoin"
                                (ngModelChange)="onChange($event)">
                                <option [value]="0">Q - Quetzal</option>
                                <option [value]="1">$ - Dolar</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Tasa de Cambio</label>
                            <input type="number" class="form-control form-control-sm text-lighter"
                                [(ngModel)]="invoiceHeader.invoiceExchangeRate" [disabled]="moneda == 'Q'" />
                        </div>
                        <div class="col-md-2">
                            <label>Origen Compra</label>
                            <select class="form-control form-control-sm text-lighter" [ngModel]="invoiceHeader.origenCompra"
                                (ngModelChange)="onChangeOrigen($event)">
                                <option [value]="1">Comer en Restaurante</option>
                                <option [value]="2">Para Llevar</option>
                                <option [value]="3">Pedidos Ya</option>
                            </select>
                        </div>
                        <!-- <div class="col-md-2">
                            <label>Seleccione la cuenta</label>
                            <select id="clientNITForCheckDropdown" class="form-control form-group" [ngModel]="checkClientNIT" (ngModelChange)="addCheckClientNIT($event)" 
                                [disabled]="!divideInChecksEnabled">
                                <option value=1>1</option>
                            </select>
                        </div> -->
                    </div>

                    <!-- BOTONES PARA COMPLETAR VENTA -->
                    <div class="row mt-4">
                        <div class="col-6 text-center">
                            <button class="btn btn-warning btn-sale" (click)="insertInvoice(1)" style="width: 100%; height: 4rem;">✔ Realizar Venta con Factura</button> <!--[disabled]="isDisabledInvoice"-->
                        </div>
                        <div class="col-6 text-center">
                            <button class="btn btn-warning btn-sale" (click)="insertInvoice(2)" style="width: 100%; height: 4rem;">✔ Realizar Venta con Recibo</button> <!--[disabled]="isDisabledInvoice"-->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    <!-- FIN BUSCAR NIT -->
    <!-- FIN MODULO CLIENTE -->    

    <!-- DETALLE DE ORDEN -->
    <div class="col-md-12 row justify-content-center" *ngIf="isBilling && isMethodPay && PaymentStatus && !isPrintT && !isPrintOrder && showOrderDetail">
        <div class="card">
            <div class="card-body">

                <button class="btn btn-warning btn-sale text-center" style="width: 30%;" (click)="lockOrders()"> <i class="now-ui-icons arrows-1_minimal-left"></i> MODIFICAR ORDEN</button>
                
                <div class="row mt-3 mb-3">
                    <div class="col-sm-12"><b>Sucursal:</b> {{storeName}} - Mesa No. {{mesaOrden}}</div>
                </div>

                <!-- Detalle de Orden -->
                <div><b>Detalle de la orden completa</b></div>
                <button class="btn btn-warning btn-sale text-center" style="width: 15%;" (click)="selectAllProduct()" *ngIf="!all">TODO</button>
                <button class="btn btn-warning btn-sale text-center" style="width: 15%;" (click)="unselectAllProduct()" *ngIf="all">QUITAR</button>
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr style="font-weight: bold;">
                                    <td class="text-center">Seleccionado</td>
                                    <td class="text-center">Cantidad</td>
                                    <td class="text-center">Descripción</td>
                                    <td class="text-center">P/U con IVA ({{moneda}})</td>
                                    <td class="text-center">Descuento</td>
                                    <td class="text-center">Total ({{moneda}})</td>
                                    <!-- <td class="text-center">No. de Cuenta</td> -->
                                    <td class="text-center">Impuesto ({{moneda}})</td>
                                    <td class="text-center">Opción</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of invoiceLine, let i=index">
                                    <!-- <td class="text-center">{{i+1}}</td> -->
                                    <td class="text-center">
                                        <input class="form-check-input" type="checkbox" value="false" [ngModel]="isChecked" (ngModelChange)="addProductInCheck(e, $event)" />
                                        <!-- <mat-checkbox (change)="addProductInCheck(e, $event)"> check</mat-checkbox> -->
                                    </td>
                                    <td class="text-center">
                                        <input type="number" class="form-control" [(ngModel)]="e.quantity"
                                            (blur)="ApplyDiscount($event, e.itemCode, e.discount)"
                                            style="max-width: 90px; margin: 0px auto;" />
                                    </td>
                                    <td>{{e.description}}</td>
                                    <td class="text-right">{{e.unitPrice + e.taxOilUP | number:'1.2-2'}}</td>
                                    <td style="text-align: right;">
                                        <input type="number" class="form-control" [(ngModel)]="e.discount"
                                            (blur)="ApplyDiscount($event, e.itemCode, e.discount)"
                                            style="max-width: 90px; margin: 0px auto;"
                                            [disabled]="e.isOil == 1" />
                                    </td>
                                    <td class="text-center">{{e.total | number:'1.2-2'}}</td>
                                    <!-- <td class="text-center">
                                        <select class="form-control form-group productInCheckDropdown" [ngModel]="checkSelected" (ngModelChange)="addProductInCheck(e, $event)"
                                            [disabled]="!divideInChecksEnabled">
                                            <option value=1>1</option>
                                        </select>
                                    </td> -->
                                    <td class="text-center" style="color: #b7b7b7;">{{e.taxAmount | number:'1.2-2'}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-danger btn-sm" (click)="deleteLine(e.itemCode)"
                                            [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                            <i class="now-ui-icons ui-1_simple-remove"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-4" style="text-align: end;">
                    <button class="btn btn-warning btn-sale" (click)="saveOrder()" [disabled]="!updateOrderFromPayment" [hidden]="!updateOrderFromPayment">
                        Actualizar orden
                    </button>
                </div>
                
                <!-- Totales y subtotales -->
                <div class="col-md-12 mt-3" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                    Subtotal: <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                    {{amountToPay | number:'1.2-2'}}
                </div>
                <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                    <small *ngIf="invoiceHeader.invoiceIDP > 0" style="font-weight: bold; font-size: 20px;">IDP
                        Q{{invoiceHeader.invoiceIDP | number:'1.2-2'}} &nbsp;&nbsp;</small>
                    Total de venta: <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                    {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                </div>

                <div class="col-md-12 mt-3" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;" *ngIf="amountToPay > 0">
                    <button class="btn btn-warning btn-sale text-center" style="width: 30%;" (click)="goToPaymentMethods()">FORMAS DE PAGO <i class="now-ui-icons arrows-1_minimal-right"></i></button>
                </div>
                <br>
            </div>
        </div>
    </div>
    <!-- DETALLE DE ORDEN --> 
    
    <!-- MODULO FORMAS DE PAGO -->
    <div class="col-md-12 row justify-content-center mt-3" *ngIf="isBilling && isMethodPay && PaymentStatus && !isPrintT && !isPrintOrder && showPaymentMethods">
        <div class="card">
            <div class="card-body">
                
                <button class="btn btn-warning btn-sale text-center" style="width: 30%;" (click)="backToOrderDetail()"> <i class="now-ui-icons arrows-1_minimal-left"></i> VER DETALLE DE LA ORDEN</button>

                <!-- Selección de formas de pago -->
                <div class="mt-3"><b>Selección de formas de pago</b></div>
                <div class="row mb-2">
                    <div class="col-md-3">
                        <label>Seleccione una forma de pago</label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                            [settings]="dropdownSettingsPayment" [data]="PaymentList" [(ngModel)]="selectedItemsPayment"
                            (onSelect)="onItemSelectPaymet($event)" [disabled]='isEdit'>
                        </ng-multiselect-dropdown>
                    </div>   
                    <div class="col-md-2">
                        <label>Monto ({{moneda}})</label>
                        <div class="form-group">
                            <input type="number" class="form-control"
                                [(ngModel)]='MontoPayment' placeholder="Monto" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>No. de Autorización</label>
                        <div class="form-group">
                            <input type="text" class="form-control"
                                [(ngModel)]='Autorizacion' placeholder="Monto" />
                        </div>
                    </div>
                    <!-- <div class="col-md-2">
                        <label>Seleccione la cuenta</label>
                        <select id="paymentMethodForCheckDropdown" class="form-control form-group" [ngModel]="checkPaymentMethod" (ngModelChange)="addCheckPaymentMethod($event)" 
                            [disabled]="!divideInChecksEnabled">
                            <option value=1>1</option>
                        </select>
                    </div> -->
                    <div class="col-md-2 mt-4">
                        <button type="submit" class="btn btn-warning btn-sale" (click)="addPaymentDetail()" [toggleable]="true" [disabled]="disabledPay" >Agregar</button> <!-- || !addPaymentMethodEnabled -->
                    </div>
                </div>

                <!-- Tabla de detalle de Formas de Pago -->
                <div><b>Formas de pago seleccionadas</b></div>
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-hover ">
                            <thead>
                                <tr style="font-weight: bold;">
                                    <td class="text-center">No.</td>
                                    <td class="text-left">Forma de Pago</td>
                                    <td class="text-center">Monto ({{moneda}})</td>
                                    <td class="text-center">Autorización</td>
                                    <td class="text-center">Opción</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of PaymentLine, let i=index">
                                    <td class="text-center">{{i+1}}</td>                                        
                                    <td>{{e.NamePaymentType}}</td>
                                    <td class="text-center">{{e.MontoPayment | number:'1.2-2'}}</td>
                                    <td>{{e.Autorizacion}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-danger btn-sm" (click)="deletePaymentDetail(i)" 
                                            [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                            <i class="now-ui-icons ui-1_simple-remove"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Agregar +100 de efectivo -->
                <div class="col-md-12" *ngIf="isMethodPay && isMoney">
                    <div class="col-md-4">
                        <label>Efectivo Recibido {{moneda}}</label>
                        <div class="form-group">
                            <input type="number" class="form-control" [(ngModel)]='Money' (keyup)="calculateChangeMoney($event)" placeholder="Monto" />
                        </div>
                        <button type="button" class="btn btn-warning btn-lg btn-gen" (click)="amountAdd()">100 +</button>
                    </div>
                    <div class="col-md-12" style="text-align: start; font-weight: bold; font-size: 25px; padding-right: 35px;">
                        Cambio <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                        {{changeMoney | number:'1.2-2'}}
                    </div>
                </div>
                <!-- Fin formas de pago -->

                <!-- Totales y subtotales -->
                <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                    Pagando: <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                    {{MontoTotalPayment | number:'1.2-2'}}
                </div>
                <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                    Subtotal: <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                    {{amountToPay | number:'1.2-2'}}
                </div>

                <div class="col-md-12 mt-3" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;" *ngIf="!isDisabledInvoice">
                    <button class="btn btn-warning btn-sale text-center" style="width: 30%;" (click)="goToInvoiceInformation()">INFORMACION DE FACTURACION <i class="now-ui-icons arrows-1_minimal-right"></i></button>
                </div>

            </div>
        </div>
    </div>
    <!-- FIN MODULO FORMAS DE PAGO --> 
    
    <!-- Imprimir Factura Ticket -->
    <div class="row justify-content-center" *ngIf="isPrintT && !isPrintTR">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-info btn-round" (click)="printTest()" style="width: 100%;">
                                <i class="now-ui-icons files_paper"></i> Imprimir
                            </button>
                        </div>
                        <div class="col-md-6">
                            <a class="btn btn-success btn-round" (click)="continue()" style="width: 100%;">
                                <i class="now-ui-icons ui-1_check"></i> Continuar
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div id="printSection" #printSection style="width: 226px; margin: 0px auto;">
                        <table style="width: 90%;">
                            <tr>
                                <td style="width: 100%; text-align: center;">
                                    <br>FACTURA
                                    <br>--DATOS DEL EMISOR--
                                    <br>{{fel.transmitter.transmitterName | uppercase}}
                                    <br>Nit: {{fel.transmitter.transmitterTaxDocument}}
                                    <br>{{fel.transmitter.transmitterComercialName | uppercase}}
                                    <br>{{fel.transmitter.transmitterAddress}},
                                    {{fel.transmitter.transmitterMunicipality}},
                                    {{fel.transmitter.transmitterDepartment}}.                                                                        
                                    <br>Serie:
                                    <br>{{dte.serie}} 
                                    <br>No. DTE:
                                    <br>{{dte.noFactura}}
                                    <br>-------------------------------------
                                    <br>DATOS DE COMPRADOR
                                    <br>{{fel.invoice.invoiceDate | date:'dd-MM-yyyy hh:mm:ss'}}
                                    <br>Nit: {{fel.receiver.receiverTaxDocument}}
                                    <br>Nombre: {{fel.receiver.receiverName}}
                                </td>
                            </tr>
                            <tr>
                                <table style="width: 100%;">
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>DETALLE DE VENTA
                                            <br>------------------------------------
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cant.</td>
                                        <td>Desc.</td>
                                        <td style="text-align: right;">Precio</td>
                                    </tr>
                                    <tr *ngFor="let e of felDetail">
                                        <td>{{e.quantity | number:'1.2-2'}}</td>
                                        <td>{{e.description}}</td>
                                        <td style="text-align: right;">
                                            {{e.total | number:'1.2-2'}}
                                            <!-- {{e.unitPrice + e.taxOilUP | number:'1.2-2'}} -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <span *ngIf="fel.invoice.invoiceOilTaxValue != 0"><br>IDP:
                                                {{fel.invoice.invoiceOilTaxValue | number:'1.2-2'}}</span>
                                            <br>IVA: {{fel.invoice.invoiceTaxValue | number:'1.2-2'}}
                                            <br><b>TOTAL: Q {{fel.invoice.invoiceTotal | number:'1.2-2'}}</b>
                                        </td>                                        
                                    </tr>
                                    <tr>
                                        <td colspan="3" *ngIf="fel.invoice.invoiceOilTaxValue != 0">
                                            <em>Esta factura incluye apoyo social temporal Decreto 20-2022 por un valor de GTQ {{subsidio | number:'1.2-2'}}</em>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                            <tr>
                                <td style="text-align: center;">
                                    -------------------------------------
                                    <br>NUMERO DE AUTORIZACIÓN
                                    <br><span style="font-weight: normal;">
                                        {{dte.autorizacion}}
                                    </span>
                                    <br>Fecha Certificación
                                    <br>{{dte.fechaCertificacion}}
                                    <br>Sujeto a pagos trimestrales ISR
                                    <br>-------------------------------------
                                    <br>CERTIFICADOR
                                    <br>{{dte.certificador | uppercase}} 
                                    <br>Nit: {{dte.nitCertificador}}
                                    <br>¡Gracias por tu compra!
                                    <br>---------------------
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin imprimir Ticket factura -->


    <!-- Imprimir Recibo Ticket -->
    <div class="row justify-content-center" *ngIf="isPrintT && isPrintTR">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-info btn-round" (click)="printTest()" style="width: 100%;">
                                <i class="now-ui-icons files_paper"></i> Imprimir
                            </button>
                        </div>
                        <div class="col-md-6">
                            <a class="btn btn-success btn-round" (click)="continue()" style="width: 100%;">
                                <i class="now-ui-icons ui-1_check"></i> Continuar
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div id="printSection" #printSection style="width: 226px; margin: 0px auto;">
                        <table style="width: 90%;">
                            <tr>
                                <td style="width: 100%; text-align: center;">
                                    <br>RECIBO
                                    <br>--DATOS DEL EMISOR--
                                    <br>{{fel.transmitter.transmitterName | uppercase}}
                                    <br>Nit: {{fel.transmitter.transmitterTaxDocument}}
                                    <br>{{fel.transmitter.transmitterComercialName | uppercase}}
                                    <br>{{fel.transmitter.transmitterAddress}},
                                    {{fel.transmitter.transmitterMunicipality}},
                                    {{fel.transmitter.transmitterDepartment}}.                                                                        
                                    <br>-------------------------------------
                                    <br>DATOS DEL RECIBO
                                    <br>Serie:
                                    <br>{{fel.invoice.invoiceInternSerial}} 
                                    <br>No. Recibo:
                                    <br>{{fel.invoice.invoiceInternNumber}}
                                    <br>Emisión:
                                    <br>{{fel.invoice.invoiceDate | date:'dd-MM-yyyy hh:mm:ss'}}
                                    <br>-------------------------------------
                                    <br>DATOS DE COMPRADOR                                    
                                    <br>Nit: {{fel.receiver.receiverTaxDocument}}
                                    <br>Nombre: {{fel.receiver.receiverName}}
                                </td>
                            </tr>
                            <tr>
                                <table style="width: 100%;">
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>DETALLE DE VENTA
                                            <br>------------------------------------
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cant.</td>
                                        <td>Desc.</td>
                                        <td style="text-align: right;">Precio</td>
                                    </tr>
                                    <tr *ngFor="let e of felDetail">
                                        <td>{{e.quantity | number:'1.2-2'}}</td>
                                        <td>{{e.description}}</td>
                                        <td style="text-align: right;">
                                            {{e.total | number:'1.2-2'}}
                                            <!-- {{e.unitPrice + e.taxOilUP | number:'1.2-2'}} -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <span *ngIf="fel.invoice.invoiceOilTaxValue != 0"><br>IDP:
                                                {{fel.invoice.invoiceOilTaxValue | number:'1.2-2'}}</span>
                                            <!-- <br>IVA: {{fel.invoice.invoiceTaxValue | number:'1.2-2'}} -->
                                            <br><b>TOTAL: Q {{fel.invoice.invoiceTotal | number:'1.2-2'}}</b>
                                        </td>                                        
                                    </tr>
                                    <tr>
                                        <td colspan="3" *ngIf="fel.invoice.invoiceOilTaxValue != 0">
                                            <em>Esta factura incluye apoyo social temporal Decreto 20-2022 por un valor de GTQ {{subsidio | number:'1.2-2'}}</em>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                            <tr>
                                <td style="text-align: center;">
                                    -------------------------------------
                                    <br>No. DE AUTORIZACIÓN RECIBO
                                    <br><span style="font-weight: normal;">
                                        {{fel.invoice.invoiceCode}}
                                    </span>                                    
                                    <br>¡Gracias por tu compra!
                                    <br>---------------------
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin imprimir Ticket Recibo -->

    <!-- Imprimir Orden -->
    <div class="row justify-content-center" *ngIf="isPrintOrder">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-info btn-round" (click)="printTestOrder()" style="width: 100%;">
                                <i class="now-ui-icons files_paper"></i> Imprimir
                            </button>
                        </div>
                        <div class="col-md-6">
                            <a class="btn btn-success btn-round" (click)="continueOrder()" style="width: 100%;">
                                <i class="now-ui-icons ui-1_check"></i> Continuar
                            </a>
                        </div>
                    </div>
                    <hr>
                    <div id="printSectionOrder" #printSectionOrder style="width: 226px; margin: 0px auto;">
                        <table style="width: 90%;">
                            <tr>
                                <td style="width: 100%; text-align: center;">
                                    <br>ORDEN No. {{orderID}}
                                    <br>Emisión:
                                    <br>{{nowDay | date:'dd-MM-yyyy'}}
                                    <br>-------------------------------------                                    
                                </td>
                            </tr>
                            <tr>
                                <table style="width: 100%;">
                                    <tr>
                                        <td colspan="3" style="text-align: right;">
                                            ------------------------------------
                                            <br>DETALLE DE ORDEN
                                            <br>------------------------------------
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cant.</td>
                                        <td>Desc.</td>                                        
                                    </tr>
                                    <tr *ngFor="let detO of invoiceLine">
                                        <td>{{detO.quantity | number:'1.2-2'}}</td>
                                        <td>{{detO.description}}</td>                                        
                                    </tr>                                                                        
                                </table>
                            </tr>
                            <tr>
                                <td style="text-align: center;">
                                    -------------------------------------                                               
                                    <br>¡Gracias por tu compra!
                                    <br>---------------------
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Imprimir Orden -->

</div>