import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import swal from "sweetalert2";

import { Data } from 'src/app/models/data';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { UtilsService } from 'src/app/services/utils.service';
import { RestaurantService } from 'src/app/services/restaurant.service'
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-restaurant-command',
  templateUrl: './restaurant-command.component.html',
  styleUrls: ['./restaurant-command.component.scss'],
  providers: [UtilsService, ProductService, RestaurantService]
})

export class restaurantCommandComponent implements OnInit {
  @ViewChild('formulario') formulario!: NgForm;

  // MODELS VARIABLE 
  public product = {} as Product;
  public data = {} as Data;
  public isEdit: boolean = false;
  public loading: boolean = false;
  public isDisabled: boolean = false;
  public userCode: number;
  public companyCode: number;

  // VARIABLE FOR DATA TABLE
  public temp = [];
  public activeRow: any;
  public entries: number = 10;
  public selected: any[] = [];
  public productList: any[] = new Array();
  public OrderCompleteList: any[] = new Array();
  public OrderList: any[] = new Array();
  public ProductOrderList: any[] = new Array();
  public storeCode: number;
  public dateUpdateOrders: string;

  constructor(
    private _utilsService: UtilsService,
    private _productService: ProductService,
    private _restaurantService: RestaurantService,
  ) { }

  ngOnInit() {
    // GET ID USER & ID COMPANY    
    const _user = JSON.parse(localStorage.getItem('user'));
    this.data.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.data.companyCode = _user.userCompany ? parseInt(_user.userCompany.toString()) : 0;

    const _data = JSON.parse(localStorage.getItem('data'));
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;

    console.log('company', _user.companyCode);
    console.log('storeCode', this.storeCode);
    this.companyCode = _user.companyCode;
    this.product.productCompany = _user.companyCode;

    // LOAD INFORMATION
    this.getAll(_user.companyCode);
    this.getAllOrdersPending();
    this.readCommand();
  }

  readCommand(){  

    setTimeout(async () => {
      await this.getAllOrdersPending();
      this.readCommand();
    }, 60000); 
    
    // (function repeat(){
    //   //if (++i > 5) return;
    //   ++i
    //   //let nowx = formatDate( new Date(), "dd-MM-yyyy hh:mm:ss","en-US");
    //   setTimeout(function(){
    //     // console.log("waited for: " + i + " seconds " + nowx);
    //     // this.dateUpdateOrders = nowx;        
    //     repeat();
    //   }, 30000);          

    // })();
  }

  async getAllOrdersPending(){

    this.OrderList = [];
    this.OrderCompleteList = [];
    let nowx = formatDate( new Date(), "dd-MM-yyyy hh:mm:ss","en-US");
    this.dateUpdateOrders = nowx;
    console.log("updated for: " + nowx);
    return this._restaurantService.getOrder(this.storeCode, 9999, 1).then(
      async response => {
        if (response.result) {
          //SI LA MESA TIENE ORDEN ABIERTA
          if(response.records.length >= 1){            
            console.log('ordenes',response.records);
            console.log('Detalle Orden',JSON.parse(response.records[0].restaurantOrdersDetail));
            //this.OrderList = response.records;
            //this.ProductOrderList = JSON.parse(response.records[0].restaurantOrdersDetail);
            //console.log('OrderList',this.OrderList);

            let i = 0;
            response.records.forEach(e => {
              console.log('i',i);
              this.OrderCompleteList.push({
                restaurantOrdersCode: e.restaurantOrdersCode,
                restaurantOrdersDetail: JSON.parse(e.restaurantOrdersDetail),
                restaurantOrdersStatus: e.restaurantOrdersStatus,
                restaurantOrdersStoreCode: e.restaurantOrdersStoreCode,
                restaurantOrdersTableCode: e.restaurantOrdersTableCode,
                restaurantOrdersUser: e.restaurantOrdersUser,
              });

              console.log('OrderCompleteList',this.OrderCompleteList);

              let detOrder = JSON.parse(e.restaurantOrdersDetail);

              detOrder.forEach((d) => {

                if(d.commandStatus == "0"){

                  this.ProductOrderList.push({
                    adds: [],
                    commandStatus: d.commandStatus,
                    description: d.description,
                    discount: d.discount,
                    inCheck: d.inCheck,
                    isOil: d.isOil,
                    isService: d.isService,
                    itemCode: d.itemCode,
                    measure: d.receiptStatus,
                    price: d.price,
                    quantity: d.quantity,
                    subsidio: d.subsidio,
                    taxAmount: d.taxAmount,
                    taxCode: d.taxCode,
                    taxName: d.taxName,
                    taxOilAmount: d.taxOilAmount,
                    taxOilCode: d.taxOilCode,
                    taxOilName: d.taxOilName,
                    taxOilQuantity: d.taxOilQuantity,
                    taxOilUP: d.taxOilUP,
                    taxableAmount: d.taxableAmount,
                    total: d.total,
                    unitPrice: d.unitPrice
                  });                  

                }                
                
              });

              if(this.ProductOrderList.length > 0){

                this.OrderList.push({
                  restaurantOrdersCode: this.OrderCompleteList[i].restaurantOrdersCode,
                  restaurantOrdersDetail: this.ProductOrderList,
                  restaurantOrdersStatus: this.OrderCompleteList[i].restaurantOrdersStatus,
                  restaurantOrdersStoreCode: this.OrderCompleteList[i].restaurantOrdersStoreCode,
                  restaurantOrdersTableCode: this.OrderCompleteList[i].restaurantOrdersTableCode,
                  restaurantOrdersUser: this.OrderCompleteList[i].restaurantOrdersUser,
                });
  
              }

              this.ProductOrderList = [];
              i++;

              console.log('OrderList',this.OrderList);

            });            

          }
          //NUEVA ORDEN
          else{
            this._utilsService.showNotification(1, "No hay ordenes pendientes");
          }
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      // this.readCommand();
    });    

  }

  async closeCommand(OrderID){

    this.loading = true;
    const _selectedOrder = await this.OrderCompleteList.filter(x => x.restaurantOrdersCode == OrderID);
    console.log('_selectedOrder ->', _selectedOrder);
    console.log('_selectedOrder -> restaurantOrdersDetail', _selectedOrder[0].restaurantOrdersDetail);

    const OrderDetailCommand = [];
    _selectedOrder[0].restaurantOrdersDetail.forEach((d) => {

      OrderDetailCommand.push({
        adds: [],
        commandStatus: "1",
        description: d.description,
        discount: d.discount,
        inCheck: d.inCheck,
        isOil: d.isOil,
        isService: d.isService,
        itemCode: d.itemCode,
        measure: d.measure,
        price: d.price,
        quantity: d.quantity,
        subsidio: d.subsidio,
        taxAmount: d.taxAmount,
        taxCode: d.taxCode,
        taxName: d.taxName,
        taxOilAmount: d.taxOilAmount,
        taxOilCode: d.taxOilCode,
        taxOilName: d.taxOilName,
        taxOilQuantity: d.taxOilQuantity,
        taxOilUP: d.taxOilUP,
        taxableAmount: d.taxableAmount,
        total: d.total,
        unitPrice: d.unitPrice,
      });
      
    });

    console.log('OrderDetailCommand', OrderDetailCommand);

    let json = {
      "store": {
        "storeCode": _selectedOrder[0].restaurantOrdersStoreCode
      },
      "user": {
        "userCode": _selectedOrder[0].restaurantOrdersUser
      },
      "table": {
        "tableCode": _selectedOrder[0].restaurantOrdersTableCode
      },
      "detail": OrderDetailCommand,
      "orderCode": _selectedOrder[0].restaurantOrdersCode,
      "status": 1 //Abierta
    };

    console.log('json', json);

    await this._restaurantService.updateOrder(json).then(

      (response) => {
        if (response.result) {            
          this._utilsService.showNotification(1, response.message);
          this.getAllOrdersPending();
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }

    ).finally(() => {
      this.loading = false;      
    });

  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.productList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL COMPANY PLAN
  getAll(company) {
    this.loading = true;

    return this._productService.getAll(company).then(
      response => {
        if (response.result) {
          this.productList = response.records;
          this.temp = this.productList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  validate(type: number, name: string): boolean {
    switch (type) {
      case 1: return this.formulario?.controls[name]?.errors
        && this.formulario?.controls[name]?.touched;
        break;
        case 2: return this.formulario?.controls[name]?.value < 0;
        break;     
    }
  }
  validate_number(type: number, name: string, name_two?: string): void {
    switch (type) {
      case 1: (this.product[name] < 0) && (this.product[name] = 0);
        break;
      case 2: (this.product[name] < 0 || this.product[name] > this.product[name_two]) && (this.product[name] = 0);;
        break;
    }
  }
  // INSERT
  save() {
    if (this.formulario.valid ) {
      this.isDisabled = true;
      this.loading = true;

      this.product.productStatus = 1;
      this.product.productDeleted = 0;
      this.product.productCompany = this.companyCode.toString();

      console.log('Guardar', this.product);
      this._productService.insert(this.product).then(
        async (response) => {
          if (response.result == true) {            

            console.log('response',response.records[0].insertId);
            
            if (!this.isObjectEmpty(this.state)) {
              const file = this.state.files.item(0);
              await this.convertBase64(file).then(async (data: string) => {
                await this._productService.uploadImage(
                  response.records[0].insertId,
                  data
                );
              });
            }


            this.getAll(this.product.productCompany);
            this.cleanModel();
            this._utilsService.showNotification(1, response.message);
          } else {
            this._utilsService.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this._utilsService.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    } else {
      this.formulario.control.markAllAsTouched();
      this._utilsService.showNotification(2, 'Falto llenar campos');
    }
  }

  // UPDATE
  update() {
    if (this.formulario.valid) {
    this.loading = true;
    this.isDisabled = true;

    this._productService.update(this.product).then(
      async (response) => {
        if (response.result == true) {
          this.getAll(this.product.productCompany);
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isDisabled = false;
    });
  } else {
    this.formulario.control.markAllAsTouched();
    this._utilsService.showNotification(2, 'Falto llenar campos');
  }
}

  // DELETED
  async deleteFunction($event) {
    $event.preventDefault();

    this.product.productStatus = 0;
    this.product.productDeleted = 1;
    this.product.productCode = this.activeRow.productCode;
    this.product.productName = this.activeRow.productName;

    swal.fire({
      title: "Esta seguro",
      text: "El tipo de empresa se eliminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.product.productStatus = 1;
    this.product.productDeleted = 0;
    this.product.productCode = this.activeRow.productCode;
    this.product.productName = this.activeRow.productName;
  }

  ///////////////////////COMPLEMENT///////////////////////  
  // CLEAR MODAL
  cleanModel(cancel?: boolean) {
    //(!cancel) && this.getAll();
    this.isEdit = false;
    this.isDisabled = false;
    this.formulario.resetForm();
    this.product = {} as Product;
  }

  // FUNCTION FOR IMAGENS
  public state: any = {};
  addFile(e) {
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;
  }

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  //verificar si esta vacio el state
  isObjectEmpty = function (state) {
    return Object.keys(state).length === 0;
  };
}
