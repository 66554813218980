import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import { Store } from 'src/app/models/store';
import { StoreService } from 'src/app/services/store.service';
import { AccessService } from 'src/app/services/access.service';
import { CompanyService } from 'src/app/services/company.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
  providers: [UtilsService, StoreService, CompanyService, AccessService]
})
export class StoreComponent implements OnInit {
  // MODELS VARIABLE 
  public store = {} as Store;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public companyCodeTemp: number;
  public isEdit: boolean;
  public isDisabled: boolean;
  public settings = {
    columns: {
      empresa: {
        title: 'Empresa'
      },
      storeName: {
        title: 'Nombre de Sucursal'
      },
      storePhone: {
        title: 'Telefono'
      },
      storeAddress: {
        title: 'Direccion'
      },
      storeEmail: {
        title: 'Correo'
      },
      storeZipCode: {
        title: 'Codigo Postal'
      },
      storeDepartment: {
        title: 'Departamento'
      },
      storeMunicipality: {
        title: 'Municipio'
      }
    }
  };
  loading;

  // VARIABLE FOR DATA TABLE
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public storeList: Store[] = new Array();

  // VARIABLE FOR SELECT 
  public companyList: any[] = new Array();
  public selectedItems = [];
  public selectedItemsStores = [];
  public dropdownSettings = {};
  public dropdownSettingsDep = {};
  public dropdownSettingsMun = {};

  public selectedItemsDep = [];
  public selectedItemsMun = [];
  departmentList = [
    { "id": "Alta Verapaz", "itemName": "Alta Verapaz" },
    { "id": "Baja Verapaz", "itemName": "Baja Verapaz" },
    { "id": "Chimaltenango", "itemName": "Chimaltenango" },
    { "id": "Chiquimula", "itemName": "Chiquimula" },
    { "id": "El Progreso", "itemName": "El Progreso" },
    { "id": "Escuintla", "itemName": "Escuintla" },
    { "id": "Guatemala", "itemName": "Guatemala" },
    { "id": "Huehuetenango", "itemName": "Huehuetenango" },
    { "id": "Izabal", "itemName": "Izabal" },
    { "id": "Jalapa", "itemName": "Jalapa" },
    { "id": "Jutiapa", "itemName": "Jutiapa" },
    { "id": "Petén", "itemName": "Petén" },
    { "id": "Quetzaltenango", "itemName": "Quetzaltenango" },
    { "id": "Quiché", "itemName": "Quiché" },
    { "id": "Retalhuleu", "itemName": "Retalhuleu" },
    { "id": "Sacatepéquez", "itemName": "Sacatepéquez" },
    { "id": "San Marcos", "itemName": "San Marcos" },
    { "id": "Santa Rosa", "itemName": "Santa Rosa" },
    { "id": "Sololá", "itemName": "Sololá" },
    { "id": "Suchitepéquez", "itemName": "Suchitepéquez" },
    { "id": "Totonicapán", "itemName": "Totonicapán" },
    { "id": "Zacapa", "itemName": "Zacapa" },
  ];

  municipalityList = [
    { "id": "Chahal", "itemName": "Chahal" },
    { "id": "Chisec", "itemName": "Chisec" },
    { "id": "Cobán", "itemName": "Cobán" },
    { "id": "Fray Bartolomé de las Casas", "itemName": "Fray Bartolomé de las Casas" },
    { "id": "Lanquín", "itemName": "Lanquín" },
    { "id": "Panzós", "itemName": "Panzós" },
    { "id": "Raxruha", "itemName": "Raxruha" },
    { "id": "San Cristóbal Verapaz", "itemName": "San Cristóbal Verapaz" },
    { "id": "San Juan Chamelco", "itemName": "San Juan Chamelco" },
    { "id": "San Pedro Carchá", "itemName": "San Pedro Carchá" },
    { "id": "Santa Cruz Verapaz", "itemName": "Santa Cruz Verapaz" },
    { "id": "Senahú", "itemName": "Senahú" },
    { "id": "Tactic", "itemName": "Tactic" },
    { "id": "Tamahú", "itemName": "Tamahú" },
    { "id": "Tucurú", "itemName": "Tucurú" },
    { "id": "Santa María Cahabón", "itemName": "Santa María Cahabón" },
    { "id": "Santa Catarina La Tinta", "itemName": "Santa Catarina La Tinta" },
    { "id": "Cubulco", "itemName": "Cubulco" },
    { "id": "Granados", "itemName": "Granados" },
    { "id": "Purulhá", "itemName": "Purulhá" },
    { "id": "Rabinal", "itemName": "Rabinal" },
    { "id": "Salamá", "itemName": "Salamá" },
    { "id": "San Jerónimo", "itemName": "San Jerónimo" },
    { "id": "San Miguel Chicaj", "itemName": "San Miguel Chicaj" },
    { "id": "Santa Cruz El Chol", "itemName": "Santa Cruz El Chol" },
    { "id": "Acatenango", "itemName": "Acatenango" },
    { "id": "Chimaltenango", "itemName": "Chimaltenango" },
    { "id": "El Tejar", "itemName": "El Tejar" },
    { "id": "Parramos", "itemName": "Parramos" },
    { "id": "Patzicía", "itemName": "Patzicía" },
    { "id": "Patzún", "itemName": "Patzún" },
    { "id": "Pochuta", "itemName": "Pochuta" },
    { "id": "San Andrés Itzapa", "itemName": "San Andrés Itzapa" },
    { "id": "San José Poaquil", "itemName": "San José Poaquil" },
    { "id": "San Juan Comalapa", "itemName": "San Juan Comalapa" },
    { "id": "San Martín Jilotepeque", "itemName": "San Martín Jilotepeque" },
    { "id": "Santa Apolonia", "itemName": "Santa Apolonia" },
    { "id": "Santa Cruz Balanyá", "itemName": "Santa Cruz Balanyá" },
    { "id": "Tecpán", "itemName": "Tecpán" },
    { "id": "Yepocapa", "itemName": "Yepocapa" },
    { "id": "Zaragoza", "itemName": "Zaragoza" },
    { "id": "Camotán", "itemName": "Camotán" },
    { "id": "Chiquimula", "itemName": "Chiquimula" },
    { "id": "Concepción Las Minas", "itemName": "Concepción Las Minas" },
    { "id": "Esquipulas", "itemName": "Esquipulas" },
    { "id": "Ipala", "itemName": "Ipala" },
    { "id": "Jocotán", "itemName": "Jocotán" },
    { "id": "Olopa", "itemName": "Olopa" },
    { "id": "Quezaltepeque", "itemName": "Quezaltepeque" },
    { "id": "San Jacinto", "itemName": "San Jacinto" },
    { "id": "San José La Arada", "itemName": "San José La Arada" },
    { "id": "San Juan Ermita", "itemName": "San Juan Ermita" },
    { "id": "El Jícaro", "itemName": "El Jícaro" },
    { "id": "Guastatoya", "itemName": "Guastatoya" },
    { "id": "Morazán", "itemName": "Morazán" },
    { "id": "San Agustín Acasaguastlán", "itemName": "San Agustín Acasaguastlán" },
    { "id": "San Antonio La Paz", "itemName": "San Antonio La Paz" },
    { "id": "San Cristóbal Acasaguastlán", "itemName": "San Cristóbal Acasaguastlán" },
    { "id": "Sanarate", "itemName": "Sanarate" },
    { "id": "Escuintla", "itemName": "Escuintla" },
    { "id": "Guanagazapa", "itemName": "Guanagazapa" },
    { "id": "Iztapa", "itemName": "Iztapa" },
    { "id": "La Democracia", "itemName": "La Democracia" },
    { "id": "La Gomera", "itemName": "La Gomera" },
    { "id": "Masagua", "itemName": "Masagua" },
    { "id": "Nueva Concepción", "itemName": "Nueva Concepción" },
    { "id": "Palín", "itemName": "Palín" },
    { "id": "San José", "itemName": "San José" },
    { "id": "San Vicente Pacaya", "itemName": "San Vicente Pacaya" },
    { "id": "Santa Lucía Cotzumalguapa", "itemName": "Santa Lucía Cotzumalguapa" },
    { "id": "Siquinalá", "itemName": "Siquinalá" },
    { "id": "Tiquisate", "itemName": "Tiquisate" },
    { "id": "Amatitlán", "itemName": "Amatitlán" },
    { "id": "Chinautla", "itemName": "Chinautla" },
    { "id": "Chuarrancho", "itemName": "Chuarrancho" },
    { "id": "Fraijanes", "itemName": "Fraijanes" },
    { "id": "Guatemala", "itemName": "Guatemala" },
    { "id": "Mixco", "itemName": "Mixco" },
    { "id": "Palencia", "itemName": "Palencia" },
    { "id": "Petapa", "itemName": "Petapa" },
    { "id": "San José del Golfo", "itemName": "San José del Golfo" },
    { "id": "San José Pinula", "itemName": "San José Pinula" },
    { "id": "San Juan Sacatepéquez", "itemName": "San Juan Sacatepéquez" },
    { "id": "San Pedro Ayampuc", "itemName": "San Pedro Ayampuc" },
    { "id": "San Pedro Sacatepéquez", "itemName": "San Pedro Sacatepéquez" },
    { "id": "San Raymundo", "itemName": "San Raymundo" },
    { "id": "Santa Catarina Pinula", "itemName": "Santa Catarina Pinula" },
    { "id": "Villa Canales", "itemName": "Villa Canales" },
    { "id": "Aguacatán", "itemName": "Aguacatán" },
    { "id": "Chiantla", "itemName": "Chiantla" },
    { "id": "Colotenango", "itemName": "Colotenango" },
    { "id": "Concepción Huista", "itemName": "Concepción Huista" },
    { "id": "Cuilco", "itemName": "Cuilco" },
    { "id": "Huehuetenango", "itemName": "Huehuetenango" },
    { "id": "Ixtahuacán", "itemName": "Ixtahuacán" },
    { "id": "Jacaltenango", "itemName": "Jacaltenango" },
    { "id": "La Democracia", "itemName": "La Democracia" },
    { "id": "La Libertad", "itemName": "La Libertad" },
    { "id": "Malacatancito", "itemName": "Malacatancito" },
    { "id": "Nentón", "itemName": "Nentón" },
    { "id": "San Antonio Huista", "itemName": "San Antonio Huista" },
    { "id": "San Gaspar Ixchil", "itemName": "San Gaspar Ixchil" },
    { "id": "San Juan Atitán", "itemName": "San Juan Atitán" },
    { "id": "San Juan Ixcoy", "itemName": "San Juan Ixcoy" },
    { "id": "San Mateo Ixtatán", "itemName": "San Mateo Ixtatán" },
    { "id": "San Miguel Acatán", "itemName": "San Miguel Acatán" },
    { "id": "San Pedro Necta", "itemName": "San Pedro Necta" },
    { "id": "San Rafael La Independencia", "itemName": "San Rafael La Independencia" },
    { "id": "San Rafael Petzal", "itemName": "San Rafael Petzal" },
    { "id": "San Sebastián Coatán", "itemName": "San Sebastián Coatán" },
    { "id": "San Sebastián Huehuetenango", "itemName": "San Sebastián Huehuetenango" },
    { "id": "Santa Ana Huista", "itemName": "Santa Ana Huista" },
    { "id": "Santa Bárbara", "itemName": "Santa Bárbara" },
    { "id": "Santa Cruz Barillas", "itemName": "Santa Cruz Barillas" },
    { "id": "Santa Eulalia", "itemName": "Santa Eulalia" },
    { "id": "Santiago Chimaltenango", "itemName": "Santiago Chimaltenango" },
    { "id": "Soloma", "itemName": "Soloma" },
    { "id": "Tectitán", "itemName": "Tectitán" },
    { "id": "Todos Santos Cuchumatan", "itemName": "Todos Santos Cuchumatan" },
    { "id": "El Estor", "itemName": "El Estor" },
    { "id": "Livingston", "itemName": "Livingston" },
    { "id": "Los Amates", "itemName": "Los Amates" },
    { "id": "Morales", "itemName": "Morales" },
    { "id": "Puerto Barrios", "itemName": "Puerto Barrios" },
    { "id": "Jalapa", "itemName": "Jalapa" },
    { "id": "Mataquescuintla", "itemName": "Mataquescuintla" },
    { "id": "Monjas", "itemName": "Monjas" },
    { "id": "San Carlos Alzatate", "itemName": "San Carlos Alzatate" },
    { "id": "San Luis Jilotepeque", "itemName": "San Luis Jilotepeque" },
    { "id": "San Manuel Chaparrón", "itemName": "San Manuel Chaparrón" },
    { "id": "San Pedro Pinula", "itemName": "San Pedro Pinula" },
    { "id": "Agua Blanca", "itemName": "Agua Blanca" },
    { "id": "Asunción Mita", "itemName": "Asunción Mita" },
    { "id": "Atescatempa", "itemName": "Atescatempa" },
    { "id": "Comapa", "itemName": "Comapa" },
    { "id": "Conguaco", "itemName": "Conguaco" },
    { "id": "El Adelanto", "itemName": "El Adelanto" },
    { "id": "El Progreso", "itemName": "El Progreso" },
    { "id": "Jalpatagua", "itemName": "Jalpatagua" },
    { "id": "Jerez", "itemName": "Jerez" },
    { "id": "Jutiapa", "itemName": "Jutiapa" },
    { "id": "Moyuta", "itemName": "Moyuta" },
    { "id": "Pasaco", "itemName": "Pasaco" },
    { "id": "Quezada", "itemName": "Quezada" },
    { "id": "San José Acatempa", "itemName": "San José Acatempa" },
    { "id": "Santa Catarina Mita", "itemName": "Santa Catarina Mita" },
    { "id": "Yupiltepeque", "itemName": "Yupiltepeque" },
    { "id": "Zapotitlán", "itemName": "Zapotitlán" },
    { "id": "Dolores", "itemName": "Dolores" },
    { "id": "Flores", "itemName": "Flores" },
    { "id": "La Libertad", "itemName": "La Libertad" },
    { "id": "Melchor de Mencos", "itemName": "Melchor de Mencos" },
    { "id": "Poptún", "itemName": "Poptún" },
    { "id": "San Andrés", "itemName": "San Andrés" },
    { "id": "San Benito", "itemName": "San Benito" },
    { "id": "San Francisco", "itemName": "San Francisco" },
    { "id": "San José", "itemName": "San José" },
    { "id": "San Luis", "itemName": "San Luis" },
    { "id": "Santa Ana", "itemName": "Santa Ana" },
    { "id": "Sayaxché", "itemName": "Sayaxché" },
    { "id": "Las Cruces", "itemName": "Las Cruces" },
    { "id": "Almolonga", "itemName": "Almolonga" },
    { "id": "Cabricán", "itemName": "Cabricán" },
    { "id": "Cajolá", "itemName": "Cajolá" },
    { "id": "Cantel", "itemName": "Cantel" },
    { "id": "Coatepeque", "itemName": "Coatepeque" },
    { "id": "Colomba", "itemName": "Colomba" },
    { "id": "Concepción Chiquirichapa", "itemName": "Concepción Chiquirichapa" },
    { "id": "El Palmar", "itemName": "El Palmar" },
    { "id": "Flores Costa Cuca", "itemName": "Flores Costa Cuca" },
    { "id": "Génova", "itemName": "Génova" },
    { "id": "Huitán", "itemName": "Huitán" },
    { "id": "La Esperanza", "itemName": "La Esperanza" },
    { "id": "Olintepeque", "itemName": "Olintepeque" },
    { "id": "Ostuncalco", "itemName": "Ostuncalco" },
    { "id": "Palestina de Los Altos", "itemName": "Palestina de Los Altos" },
    { "id": "Quetzaltenango", "itemName": "Quetzaltenango" },
    { "id": "Salcajá", "itemName": "Salcajá" },
    { "id": "San Carlos Sija", "itemName": "San Carlos Sija" },
    { "id": "San Francisco La Unión", "itemName": "San Francisco La Unión" },
    { "id": "San Martín Sacatepéquez", "itemName": "San Martín Sacatepéquez" },
    { "id": "San Mateo", "itemName": "San Mateo" },
    { "id": "San Miguel Sigüilá", "itemName": "San Miguel Sigüilá" },
    { "id": "Sibilia", "itemName": "Sibilia" },
    { "id": "Zunil", "itemName": "Zunil" },
    { "id": "Canillá", "itemName": "Canillá" },
    { "id": "Chajul", "itemName": "Chajul" },
    { "id": "Chicamán", "itemName": "Chicamán" },
    { "id": "Chiché", "itemName": "Chiché" },
    { "id": "Chichicastenango", "itemName": "Chichicastenango" },
    { "id": "Chinique", "itemName": "Chinique" },
    { "id": "Cunén", "itemName": "Cunén" },
    { "id": "Ixcán", "itemName": "Ixcán" },
    { "id": "Joyabaj", "itemName": "Joyabaj" },
    { "id": "Nebaj", "itemName": "Nebaj" },
    { "id": "Pachalum", "itemName": "Pachalum" },
    { "id": "Patzité", "itemName": "Patzité" },
    { "id": "Sacapulas", "itemName": "Sacapulas" },
    { "id": "San Andrés Sajcabajá", "itemName": "San Andrés Sajcabajá" },
    { "id": "San Antonio Ilotenango", "itemName": "San Antonio Ilotenango" },
    { "id": "San Bartolomé Jocotenango", "itemName": "San Bartolomé Jocotenango" },
    { "id": "San Juan Cotzal", "itemName": "San Juan Cotzal" },
    { "id": "San Pedro Jocopilas", "itemName": "San Pedro Jocopilas" },
    { "id": "Santa Cruz del Quiché", "itemName": "Santa Cruz del Quiché" },
    { "id": "Uspantán", "itemName": "Uspantán" },
    { "id": "Zacualpa", "itemName": "Zacualpa" },
    { "id": "Champerico", "itemName": "Champerico" },
    { "id": "El Asintal", "itemName": "El Asintal" },
    { "id": "Nuevo San Carlos", "itemName": "Nuevo San Carlos" },
    { "id": "Retalhuleu", "itemName": "Retalhuleu" },
    { "id": "San Andrés Villa Seca", "itemName": "San Andrés Villa Seca" },
    { "id": "San Felipe", "itemName": "San Felipe" },
    { "id": "San Martín Zapotitlán", "itemName": "San Martín Zapotitlán" },
    { "id": "San Sebastián", "itemName": "San Sebastián" },
    { "id": "Santa Cruz Muluá", "itemName": "Santa Cruz Muluá" },
    { "id": "Alotenango", "itemName": "Alotenango" },
    { "id": "Antigua", "itemName": "Antigua" },
    { "id": "Ciudad Vieja", "itemName": "Ciudad Vieja" },
    { "id": "Jocotenango", "itemName": "Jocotenango" },
    { "id": "Magdalena Milpas Altas", "itemName": "Magdalena Milpas Altas" },
    { "id": "Pastores", "itemName": "Pastores" },
    { "id": "San Antonio Aguas Calientes", "itemName": "San Antonio Aguas Calientes" },
    { "id": "San Bartolomé Milpas Altas", "itemName": "San Bartolomé Milpas Altas" },
    { "id": "San Lucas Sacatepéquez", "itemName": "San Lucas Sacatepéquez" },
    { "id": "San Miguel Dueñas", "itemName": "San Miguel Dueñas" },
    { "id": "Santa Catarina Barahona", "itemName": "Santa Catarina Barahona" },
    { "id": "Santa Lucía Milpas Altas", "itemName": "Santa Lucía Milpas Altas" },
    { "id": "Santa María de Jesús", "itemName": "Santa María de Jesús" },
    { "id": "Santiago Sacatepéquez", "itemName": "Santiago Sacatepéquez" },
    { "id": "Santo Domingo Xenacoj", "itemName": "Santo Domingo Xenacoj" },
    { "id": "Sumpango", "itemName": "Sumpango" },
    { "id": "Ayutla", "itemName": "Ayutla" },
    { "id": "Catarina", "itemName": "Catarina" },
    { "id": "Comitancillo", "itemName": "Comitancillo" },
    { "id": "Concepción Tutuapa", "itemName": "Concepción Tutuapa" },
    { "id": "El Quetzal", "itemName": "El Quetzal" },
    { "id": "El Rodeo", "itemName": "El Rodeo" },
    { "id": "El Tumbador", "itemName": "El Tumbador" },
    { "id": "Esquipulas Palo Gordo", "itemName": "Esquipulas Palo Gordo" },
    { "id": "Ixchiguan", "itemName": "Ixchiguan" },
    { "id": "La Reforma", "itemName": "La Reforma" },
    { "id": "Malacatán", "itemName": "Malacatán" },
    { "id": "Nuevo Progreso", "itemName": "Nuevo Progreso" },
    { "id": "Ocos", "itemName": "Ocos" },
    { "id": "Pajapita", "itemName": "Pajapita" },
    { "id": "Río Blanco", "itemName": "Río Blanco" },
    { "id": "San Antonio Sacatepéquez", "itemName": "San Antonio Sacatepéquez" },
    { "id": "San Cristóbal Cucho", "itemName": "San Cristóbal Cucho" },
    { "id": "San José Ojetenam", "itemName": "San José Ojetenam" },
    { "id": "San Lorenzo", "itemName": "San Lorenzo" },
    { "id": "San Marcos", "itemName": "San Marcos" },
    { "id": "San Miguel Ixtahuacán", "itemName": "San Miguel Ixtahuacán" },
    { "id": "San Pablo", "itemName": "San Pablo" },
    { "id": "San Pedro Sacatepéquez", "itemName": "San Pedro Sacatepéquez" },
    { "id": "San Rafael Pie de La Cuesta", "itemName": "San Rafael Pie de La Cuesta" },
    { "id": "San Sibinal", "itemName": "San Sibinal" },
    { "id": "Sipacapa", "itemName": "Sipacapa" },
    { "id": "Tacaná", "itemName": "Tacaná" },
    { "id": "Tajumulco", "itemName": "Tajumulco" },
    { "id": "Tejutla", "itemName": "Tejutla" },
    { "id": "Barberena", "itemName": "Barberena" },
    { "id": "Casillas", "itemName": "Casillas" },
    { "id": "Chiquimulilla", "itemName": "Chiquimulilla" },
    { "id": "Cuilapa", "itemName": "Cuilapa" },
    { "id": "Guazacapán", "itemName": "Guazacapán" },
    { "id": "Nueva Santa Rosa", "itemName": "Nueva Santa Rosa" },
    { "id": "Oratorio", "itemName": "Oratorio" },
    { "id": "Pueblo Nuevo Viñas", "itemName": "Pueblo Nuevo Viñas" },
    { "id": "San Juan Tecuaco", "itemName": "San Juan Tecuaco" },
    { "id": "San Rafael Las Flores", "itemName": "San Rafael Las Flores" },
    { "id": "Santa Cruz Naranjo", "itemName": "Santa Cruz Naranjo" },
    { "id": "Santa María Ixhuatán", "itemName": "Santa María Ixhuatán" },
    { "id": "Santa Rosa de Lima", "itemName": "Santa Rosa de Lima" },
    { "id": "Taxisco", "itemName": "Taxisco" },
    { "id": "Concepción", "itemName": "Concepción" },
    { "id": "Nahualá", "itemName": "Nahualá" },
    { "id": "Panajachel", "itemName": "Panajachel" },
    { "id": "San Andrés Semetabaj", "itemName": "San Andrés Semetabaj" },
    { "id": "San Antonio Palopó", "itemName": "San Antonio Palopó" },
    { "id": "San José Chacaya", "itemName": "San José Chacaya" },
    { "id": "San Juan La Laguna", "itemName": "San Juan La Laguna" },
    { "id": "San Lucas Tolimán", "itemName": "San Lucas Tolimán" },
    { "id": "San Marcos La Laguna", "itemName": "San Marcos La Laguna" },
    { "id": "San Pablo La Laguna", "itemName": "San Pablo La Laguna" },
    { "id": "San Pedro La Laguna", "itemName": "San Pedro La Laguna" },
    { "id": "Santa Catarina Ixtahuacan", "itemName": "Santa Catarina Ixtahuacan" },
    { "id": "Santa Catarina Palopó", "itemName": "Santa Catarina Palopó" },
    { "id": "Santa Clara La Laguna", "itemName": "Santa Clara La Laguna" },
    { "id": "Santa Cruz La Laguna", "itemName": "Santa Cruz La Laguna" },
    { "id": "Santa Lucía Utatlán", "itemName": "Santa Lucía Utatlán" },
    { "id": "Santa María Visitación", "itemName": "Santa María Visitación" },
    { "id": "Santiago Atitlán", "itemName": "Santiago Atitlán" },
    { "id": "Sololá", "itemName": "Sololá" },
    { "id": "Chicacao", "itemName": "Chicacao" },
    { "id": "Cuyotenango", "itemName": "Cuyotenango" },
    { "id": "Mazatenango", "itemName": "Mazatenango" },
    { "id": "Patulul", "itemName": "Patulul" },
    { "id": "Pueblo Nuevo", "itemName": "Pueblo Nuevo" },
    { "id": "Río Bravo", "itemName": "Río Bravo" },
    { "id": "Samayac", "itemName": "Samayac" },
    { "id": "San Antonio Suchitepéquez", "itemName": "San Antonio Suchitepéquez" },
    { "id": "San Bernardino", "itemName": "San Bernardino" },
    { "id": "San Francisco Zapotitlán", "itemName": "San Francisco Zapotitlán" },
    { "id": "San Gabriel", "itemName": "San Gabriel" },
    { "id": "San José El Idolo", "itemName": "San José El Idolo" },
    { "id": "San Juan Bautista", "itemName": "San Juan Bautista" },
    { "id": "San Lorenzo", "itemName": "San Lorenzo" },
    { "id": "San Miguel Panán", "itemName": "San Miguel Panán" },
    { "id": "San Pablo Jocopilas", "itemName": "San Pablo Jocopilas" },
    { "id": "Santa Bárbara", "itemName": "Santa Bárbara" },
    { "id": "Santo Domingo Suchitepequez", "itemName": "Santo Domingo Suchitepequez" },
    { "id": "Santo Tomas La Unión", "itemName": "Santo Tomas La Unión" },
    { "id": "Zunilito", "itemName": "Zunilito" },
    { "id": "Momostenango", "itemName": "Momostenango" },
    { "id": "San Andrés Xecul", "itemName": "San Andrés Xecul" },
    { "id": "San Bartolo", "itemName": "San Bartolo" },
    { "id": "San Cristóbal Totonicapán", "itemName": "San Cristóbal Totonicapán" },
    { "id": "San Francisco El Alto", "itemName": "San Francisco El Alto" },
    { "id": "Santa Lucía La Reforma", "itemName": "Santa Lucía La Reforma" },
    { "id": "Santa María Chiquimula", "itemName": "Santa María Chiquimula" },
    { "id": "Totonicapán", "itemName": "Totonicapán" },
    { "id": "Cabañas", "itemName": "Cabañas" },
    { "id": "Estanzuela", "itemName": "Estanzuela" },
    { "id": "Gualán", "itemName": "Gualán" },
    { "id": "Huité", "itemName": "Huité" },
    { "id": "La Unión", "itemName": "La Unión" },
    { "id": "Río Hondo", "itemName": "Río Hondo" },
    { "id": "San Diego", "itemName": "San Diego" },
    { "id": "Teculután", "itemName": "Teculután" },
    { "id": "Usumatlán", "itemName": "Usumatlán" },
    { "id": "Zacapa", "itemName": "Zacapa" },
  ];

  constructor(
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _storeService: StoreService,
    private _accessService: AccessService,
    private _companyService: CompanyService,
  ) { }

  async ngOnInit() {
    // GET ID USER & ID COMPANY
    this.store.storeInvoiceType = 0;
    this.store.storeDocument = "F";

    let _data = JSON.parse(localStorage.getItem('data'));
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.companyCodeTemp = this.companyCode;

    // LOAD VARIABLE
    this.isEdit = false;
    this.isDisabled = false;

    // LOAD INFORMATION
    await this.getAllCompany();
    this.companyCode == 1 ? this.getAll() : this.getAllByCompany(this.companyCodeTemp);

    // SELECT
    this.onItemSelect(this.selectedItems);
    this.getSelectSettingDep(this.selectedItemsDep);
    this.getSelectSettingMun(this.selectedItemsMun);
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.storeList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////FUNCTION FOR SELECT///////////////////////  
  getSelectSetting(selected) {
    //Object.keys(selected).length > 0 ? this.selectedItemsStores = [selected] : '';
    //Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: false,
    };
  };

  getSelectSettingDep(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsDep = [selected] : '';

    this.dropdownSettingsDep = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: ""
    };
  };

  getSelectSettingMun(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsMun = [selected] : '';

    this.dropdownSettingsMun = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: ""
    };
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getAllCompany() {
    return this._companyService.getAll().then(
      async response => {
        if (response.result == true) {

          if (this.companyCode > 1) {
            let filtro = await response.records.filter(
              x => x.companyCompanyCreationCode === this.companyCode || x.companyCode === this.companyCode
            );

            for await (const e of filtro) {
              this.companyList.push({ id: e.companyCode, itemName: e.companyName });
            }
          }else{
            for await (const e of response.records) {
              this.companyList.push({ id: e.companyCode, itemName: e.companyName });
            }
          }

          let _selectedItemsCompany = await this.companyList.find(x => x.id == this.companyCode);
          this.getSelectSetting(_selectedItemsCompany);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelect(item: any) {
    this.store.storeCompanyCode = item.id;
  }

  onItemSelectStores(item: any) {
    this.companyCodeTemp = item.id;
    this.getAllByCompany(this.companyCodeTemp);
  }

  onItemSelectDep(item: any) {
    this.store.storeDepartment = item.id;
  }
  
  onItemSelectMun(item: any) {
    this.store.storeMunicipality = item.id;
  }

  ///////////////////////CRUD///////////////////////  
  // GET ALL STORE
  getAll() {
    this.loading = true;

    return this._storeService.getAll().then(
      response => {
        if (response.result) {
          this.storeList = response.records;
          this.temp = this.storeList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ALL STORE
  getAllByCompany(companyCode) {
    this.loading = true;

    return this._storeService.getAllByCompany(companyCode).then(
      response => {
        if (response.result) {
          this.storeList = response.records;
          this.temp = this.storeList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE STORE
  viewFunction($event) {
    $event.preventDefault();
    let facturacion = '';

    switch (this.activeRow.storeInvoiceType) {
      case 1: facturacion = 'No Factura';
        break;
      case 2: facturacion = 'Inventario';
        break;
      case 3: facturacion = 'Descripción';
        break;
      case 3: facturacion = 'Hibrído';
        break;
    }

    let documentoType = "";
    switch (this.store.storeDocument){
      case "F": documentoType = "Factura";
        break;
      case "R": documentoType = "Recibo";
        break;
    }

    swal.fire({
      title: "Empresa:",
      html:
        '<div class="text-left">' +
        '<b>Facturación: ' + facturacion + '</b><br><br>' +
        '<b>Documento: ' + documentoType + '</b><br><br>' +
        '<b>Empresa: </b>' + this.activeRow.empresa + '<br>' +
        '<b>Nombre: </b>' + this.activeRow.storeName + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.storePhone + '<br>' +
        '<b>Correo: </b>' + this.activeRow.storeEmail + '<br>' +
        '<b>Establecimiento: </b>' + this.activeRow.storeEstablishmentCode + '<br>' +
        '<b>Código Postal: </b>' + this.activeRow.storeZipCode + '<br>' +
        '<b>Departamento: </b>' + this.activeRow.storeDepartment + '<br>' +
        '<b>Municipio: </b>' + this.activeRow.storeMunicipality + '<br>' +
        '<b>Dirección: </b>' + this.activeRow.storeAddress + '<br></div>',
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  // INSERT
  save() {
    this.isDisabled = true;
    this.loading = true;
    
    this.store.storeStatus = 1;
    this.store.storeDeleted = 0;
    this.store.storeMain ? this.store.storeMain = 1 : this.store.storeMain = 0;

    console.log(this.store);

    this._storeService.insert(this.store).then(
      async (response) => {
        if (response.result == true) {
          this.companyCode == 1 ? this.getAll() : this.getAllByCompany(this.companyCodeTemp);
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = true;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
      this.isEdit = false;
    });
  }

  // UPDATE
  update() {
    this.isDisabled = true;
    this.loading = true;

    this.store.storeMain ? this.store.storeMain = 1 : this.store.storeMain = 0;

    this._storeService.update(this.store).then(
      async (response) => {
        if (response.result == true) {
          this.isEdit = false;
          this.companyCode == 1 ? this.getAll() : this.getAllByCompany(this.companyCodeTemp);
          this.cleanModel();
          this._utilsService.showNotification(1, response.message);
        } else {
          this.isDisabled = false;
          this._utilsService.showNotification(3, response.errorMessage);
        }
      },
      error => {
        this.isDisabled = false;
        this._utilsService.showNotification(2, error);
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // DELETED
  deleteFunction($event) {
    $event.preventDefault();

    this.store.storeCode = this.activeRow.storeCode;
    this.store.storeCompanyCode = this.activeRow.storeCompanyCode;
    this.store.storeName = this.activeRow.storeName;
    this.store.storePhone = this.activeRow.storePhone;
    this.store.storeAddress = this.activeRow.storeAddress;
    this.store.storeEmail = this.activeRow.storeEmail;
    this.store.storeEstablishmentCode = this.activeRow.storeEstablishmentCode;
    this.store.storeStatus = 0;
    this.store.storeDeleted = 1;

    swal.fire({
      title: "Esta seguro",
      text: "La empresa se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        swal.fire({
          title: "Enviado!",
          text: "Se ha mandado la solicitud.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });
  }

  // LOAD DATA TO EDIT
  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.store.storeCode = this.activeRow.storeCode;
    this.store.storeCompanyCode = this.activeRow.storeCompanyCode;
    this.store.storeName = this.activeRow.storeName;
    this.store.storePhone = this.activeRow.storePhone;
    this.store.storeAddress = this.activeRow.storeAddress;
    this.store.storeEmail = this.activeRow.storeEmail;
    this.store.storeInvoiceType = this.activeRow.storeInvoiceType;
    this.store.storeDocument = this.activeRow.storeDocument;
    this.store.storeEstablishmentCode = this.activeRow.storeEstablishmentCode;
    this.store.storeStatus = this.activeRow.storeStatus;
    this.store.storeDeleted = this.activeRow.storeDeleted;

    this.store.storeZipCode = this.activeRow.storeZipCode;
    this.store.storeDepartment = this.activeRow.storeDepartment.trim();
    this.store.storeMunicipality = this.activeRow.storeMunicipality.trim();

    this.activeRow.storeMain == 1 ? this.store.storeMain = true : this.store.storeMain = false;

    const _selectedItemDep = this.departmentList.find(x => x.id === this.activeRow.storeDepartment.trim());
    _selectedItemDep == undefined ? this.selectedItemsDep = null : this.getSelectSettingDep(_selectedItemDep);

    const _selectedItemMun = this.municipalityList.find(x => x.id == this.activeRow.storeMunicipality.trim());
    _selectedItemMun == undefined ? this.selectedItemsMun = null : this.getSelectSettingMun(_selectedItemMun);

    const _selectedItem = this.companyList.find(x => x.id == this.activeRow.storeCompanyCode);
    this.getSelectSetting(_selectedItem);
  }

  ///////////////////////COMPLEMENT///////////////////////  
  //CANCEL EDIT
  cancelUpdate($event) {
    this.cleanModel();
    this.isEdit = false;
  }

  // CLEAR MODAL
  cleanModel() {
    this.isDisabled = false;
    this.store = {} as Store;
    this.store.storeInvoiceType = 0;
    this.store.storeDocument = "F";
    this.selectedItems = null;
    this.selectedItemsDep = null;
    this.selectedItemsMun = null;
    // this.companyCode == 1 ? this.selectedItems = null : '';
    // this.companyCode > 1 ? this.store.storeCompanyCode = this.companyCode : '';
  }

  // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.temp;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        json[this.settings.columns[item].title] = element[item];
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-sucursal.xlsx');
  }
}
