<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/store">Sucursal</a>
        </div>
    </div>
</div>

<style>
    .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
        color: #fff;
        background: #5cb85c !important;
    }
</style>

<div class="main-content">

    <!-- INPUT STORE -->
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-11">
                            <b class="card-title" *ngIf="!isEdit">Nueva Sucursal</b>
                            <b class="card-title text-warning" *ngIf="isEdit">Modificar Sucursal</b>
                        </div>
                        <div class="col-1" *ngIf="isEdit">
                            <a class="btn btn-round btn-danger btn-icon btn-sm remove btn-erp" (click)="cancelUpdate($event)"><i
                                    class="fas fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>*Empresas</label>
                                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItems"
                                    [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>*Nombre</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="store.storeName" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>*Teléfono</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="store.storePhone" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Correo</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="store.storeEmail" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>*Departamento</label>
                            <div class="form-group">
                                <angular2-multiselect [data]="departmentList" [(ngModel)]="selectedItemsDep"
                                    [settings]="dropdownSettingsDep" (onSelect)="onItemSelectDep($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>*Municipio</label>
                            <div class="form-group">
                                <angular2-multiselect [data]="municipalityList" [(ngModel)]="selectedItemsMun"
                                    [settings]="dropdownSettingsMun" (onSelect)="onItemSelectMun($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>*Dirección</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="store.storeAddress" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>*Código postal</label>
                            <div class="form-group">
                                <input type="number" class="form-control" [(ngModel)]="store.storeZipCode" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>*Establecimiento</label>
                            <div class="form-group">
                                <input type="number" class="form-control" [(ngModel)]="store.storeEstablishmentCode" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>*Tipo de Venta</label>
                                <select class="form-control" [(ngModel)]="store.storeInvoiceType">
                                    <option value=0>No factura</option>
                                    <option value=1>Con Inventario</option>
                                    <option value=2>Descripciones</option>
                                    <option value=3>Hibrido</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>*Tipo de Documento</label>
                                <select class="form-control" [(ngModel)]="store.storeDocument">
                                    <option value="F">Factura</option>
                                    <option value="R">Recibo</option>                                    
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Sucursal Central</label>
                            <div class="form-group">
                                <bSwitch [switch-on-color]="'success'"
                                    [(ngModel)]="store.storeMain"
                                    switch-on-text="<i class='now-ui-icons ui-1_check'></i>"
                                    switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                </bSwitch>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-info btn-block" (click)="save()" *ngIf="!isEdit"
                                [disabled]="isDisabled">Guardar</button>
                            <button class="btn btn-primary btn-block" (click)="update()" *ngIf="isEdit"
                                [disabled]="isDisabled">Actualizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END INPUT STORE -->

    <!-- TABLE -->
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Empresas</label>
                                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsStores"
                                    [settings]="dropdownSettings" (onSelect)="onItemSelectStores($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <h4 class="card-title">Listado de Sucursales</h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                [disabled]="isDisabled">
                                Descargar Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined"
                            [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Empresa"></ngx-datatable-column>
                            <ngx-datatable-column name="Sucursal"></ngx-datatable-column>
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" ngbTooltip="Ver"><i class="fas fa-eye"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit"
                                        (click)="editFunction($event)" ngbTooltip="Editar"><i
                                            class="fas fa-pencil-alt"></i>
                                    </a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove"
                                        (click)="deleteFunction($event)" ngbTooltip="Eliminar"><i
                                            class="fas fa-times"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>