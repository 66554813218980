import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inventory } from '../models/inventory';
import { Product } from '../models/product';
import { environment } from 'src/environments/environment';


@Injectable()
export class InventoryService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
  }

  // GET ALL INVENTORY
  getInventorySerial(code): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventorySerial/one/" + code, {
        headers: headers,
      })
      .toPromise();
  }

  getItemSerial(code): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventorySerial/item/" + code, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ALL INVENTORY
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventory", { headers: headers })
      .toPromise();
  }

  // GET ALL INVENTORY BY STORE
  getAllByStore(storeCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventory/store/" + storeCode, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ALL INVENTORY BY STORE STATUS
  getAllByStoreStatus(storeCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventory/storeStatus/" + storeCode, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ALL INVENTORY BY STORE
  getByStoreTopSales(storeCode, begDate, endDate, Top): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(
        this.url +
          "erprest-inventory/storetopsales/" +
          storeCode +
          "/" +
          begDate +
          "/" +
          endDate +
          "/" +
          Top,
        { headers: headers }
      )
      .toPromise();
  }

  // GET ALL INVENTORY BY STORE
  getFuelByStore(storeCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventory/store/gas/" + storeCode, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ALL NO. FROM ITEM
  getAllItems(storeCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventory/store/" + storeCode, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ALL RANK PRICE
  getOneRank(inventoryCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventoryPriceRank/one/" + inventoryCode, {
        headers: headers,
      })
      .toPromise();
  }

  // GET SERIAL FROM ITEM
  getOneItemsSerial(inventoryCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventorySerial/one/" + inventoryCode, {
        headers: headers,
      })
      .toPromise();
    // return this._http.get(this.url + 'erprest-inventorySerial/item/' + inventoryCode, { headers: headers }).toPromise();
  }

  getOne(code): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-inventory/one/" + code, { headers: headers })
      .toPromise();
  }

  getPriceByItem(item, quantity): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(
        this.url +
          "erprest-inventoryPriceRank/price/" +
          quantity +
          "/" +
          item,
        { headers: headers }
      )
      .toPromise();
  }

  // INSERT SERIAL
  insertSerial(json): Promise<any> {
    let params = JSON.stringify(json);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(this.url + "erprest-inventorySerial", params, {
        headers: headers,
      })
      .toPromise();
  }

  // UPDATE SERIAL
  updateSerial(json): Promise<any> {
    let params = JSON.stringify(json);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .put(this.url + "erprest-inventorySerial", params, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ALL PRODUCT
  getAllProduct(): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-product/0", { headers: headers })
      .toPromise();
  }

  // GET ALL PRODUCT
  getAllProductCompany(company): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "erprest-product/" + company, { headers: headers })
      .toPromise();
  }

  // INSERT INVENTORY
  insert(inventory: Inventory): Promise<any> {
    let params = JSON.stringify(inventory);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(this.url + "erprest-inventory", params, { headers: headers })
      .toPromise();
  }

  // INSERT PRODUCT
  insertProduct(product: Product): Promise<any> {
    let params = JSON.stringify(product);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(this.url + "erprest-product", params, { headers: headers })
      .toPromise();
  }

  // INSERT PRICE
  insertPrice(json): Promise<any> {
    let params = JSON.stringify(json);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(this.url + "erprest-inventoryPriceRank", params, {
        headers: headers,
      })
      .toPromise();
  }

  // UPDATE INVENTORY
  //update(inventory: Inventory): Promise<any> {
  update(inventory): Promise<any> {
    let params = JSON.stringify(inventory);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .put(this.url + "erprest-inventory", params, { headers: headers })
      .toPromise();
  }

  // UPDATE PRODUCT
  updateProduct(product: Product): Promise<any> {
    let params = JSON.stringify(product);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .put(this.url + "erprest-product", params, { headers: headers })
      .toPromise();
  }

  // UPDATE PRICE
  updatePrice(json): Promise<any> {
    let params = JSON.stringify(json);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .put(this.url + "erprest-inventoryPriceRank", params, {
        headers: headers,
      })
      .toPromise();
  }

  //VALIDATE BUSINESS CODE
  validateBusinessCode(businessCode, storeCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(
        this.url +
          "erprest-inventory/businessCode/" +
          businessCode +
          "/" +
          storeCode,
        { headers: headers }
      )
      .toPromise();
  }

  //Update Stock
  updateStock(json, type): Promise<any> {
    let params = JSON.stringify(json);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .put(this.url + "erprest-inventoryUpdateStock/" + type, params, {
        headers: headers,
      })
      .toPromise();
  }

  //UPLOAD PRESALE JSON FILE
  uploadImage(filename, data): Promise<any> {
    let body = JSON.stringify({
      bucket_name: "restaurant-clever",
      carpeta: "images",
      filename: filename + ".png",
      content: data,
      content_type: "image/png",
    });
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(
        "https://us-central1-clever-375004.cloudfunctions.net/upload_image",
        body,
        { headers }
      )
      .toPromise();
  }
}