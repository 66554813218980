<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <a>Home</a>
    </div>
</div>

<div class="main-content bk-im">
    <div class="header">
        <div class="row">
            <div class="col-sm-12 text-center">
                <b style="font-size: 1.25rem;">Sistema ERP</b>
            </div>
            <div class="col-sm-6 col-md-7"></div>
            <div class="col-sm-6 col-md-5 text-right">
                <label>EMPRESA</label>
                <angular2-multiselect [data]="companyList" [(ngModel)]="selectedItemsCompany"
                    [settings]="dropdownSettingsCompany" (onSelect)="onItemSelectCompany($event)" (onDeSelect)="onItemDeSelectCompany($event)">
                </angular2-multiselect>
            </div>
            <div class="col-sm-6 col-md-7"></div>
            <div class="col-sm-6 col-md-5 text-right">
                <label>SUCURSAL</label>
                <angular2-multiselect [data]="storeList" [(ngModel)]="selectedItemsStore"
                    [settings]="dropdownSettingsStore" (onSelect)="onItemSelectStore($event)" (onDeSelect)="onItemDeSelectStore($event)">
                </angular2-multiselect>
            </div>
            <div class="col-sm-12 text-right">
                <label>&nbsp;</label>
                <div class="statistics">
                    <button class="btn btn-round btn-success" (click)="changeStore($event)">
                        Cambiar
                    </button>                    
                </div>       
                <label class="">*Al cambiar recarga la página</label>     
            </div>
            <div class="col-sm-12 text-right">
                <div class="statistics">
                    <button class="btn btn-round btn-info" (click)="openStore()" *ngIf="!isStoreOpen && OpenCash">
                        Abrir caja
                    </button>
                    <button class="btn btn-round btn-info" (click)="closeStore()" *ngIf="isStoreOpen && OpenCash">
                        Cerrar caja
                    </button>
                </div>
            </div>
        </div> 

        <div class="row">
            <div class="col-sm-12 text-center">
                <b style="font-size: 1.25rem;">Top Productos Vendidos</b>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">                        
                            <div class="col-sm-12">
                                <b style="font-size: 1rem;">{{storeName}}</b>
                            </div>
                            <div class="col-md-3">
                                <label>Del:</label>
                                <div class="form-group">
                                    <input type="date" class="form-control" [(ngModel)]="begDate" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>Para:</label>
                                <div class="form-group">
                                    <input type="date" class="form-control" [(ngModel)]="endDate" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>Top:</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" [(ngModel)]="Top" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <br>
                                <div class="form-group">
                                    <button class="btn btn-info btn-sale" style="width: 100%;" (click)="getTopSales()">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- TABLE ALL -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="card-title">
                                    <br>Productos mas vendidos.
                                </h4>
                                <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                                </h6>
                            <!-- </div>
                            <div class="col-md-4">
                                <button class="btn btn-success btn-sale" style="width: 100%;" (click)="downloadToExcel()"
                                    [disabled]="isDisabled">
                                    Descargar Excel
                                </button>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="dataTables_wrapper">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="dataTables_length" id="datatable_length">
                                        <label>
                                            Show
                                            <select name="datatable_length" aria-controls="datatable"
                                                class="form-control form-control-sm" (change)="entriesChange($event)">
                                                <option value="10" [selected]="entries==10">10</option>
                                                <option value="25" [selected]="entries==25">25</option>
                                                <option value="50" [selected]="entries==50">50</option>
                                                <option value="-1" [selected]="entries==-1">All</option>
                                            </select>
                                            entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div id="datatable_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search records" aria-controls="datatable"
                                                (keyup)="filterTable($event)" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- [columnMode]="'ColumnMode.flex | .force | .standard'" [flexGrow]="3" [width]="300" [minWidth]="200" [maxWidth]="200"-->
                            <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                                [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp"
                                (activate)="onActivate($event)" [scrollbarH]="false">
                                <ngx-datatable-column name="No. Ventas" prop="Ventas"></ngx-datatable-column>
                                <ngx-datatable-column name="Stock" prop="inventoryQuantity"></ngx-datatable-column>
                                <ngx-datatable-column name="Descripción" prop="invoiceDetailDescription"></ngx-datatable-column>
                                <ngx-datatable-column name="Precio" prop="inventoryPrice"></ngx-datatable-column>
                                <!-- <ngx-datatable-column [minWidth]="200" [sortable]="false" [canAutoResize]="false"
                                    [draggable]="false" [resizeable]="false">
                                    <ng-template ngx-datatable-header-template let-value="value"
                                        let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                        Acciones
                                    </ng-template>
                                    <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                        let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                        <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                            (click)="viewFunction($event)" ngbTooltip="Ver"><i class="fas fa-eye"></i>
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column> -->
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END TABLE -->
    </div>
</div>