import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Role } from '../models/role';
import { RoleAccess } from '../models/roleAccess';
import { environment } from 'src/environments/environment';


@Injectable()
export class RoleService {

    public url: string;

    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiUrl;
    }

    // GET ALL ROLE
    getAll(): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-role', { headers: headers }).toPromise();
    }

    // GET ALL BY COMPANY
    getAllByCompany(company): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-role/company/' + company, { headers: headers }).toPromise();
    }

    // INSERT ROLE
    insert(role: Role): Promise<any> {
        let params = JSON.stringify(role);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-role', params, { headers: headers }).toPromise();
    }

    // UPDATE ROLE
    update(role: Role): Promise<any> {
        let params = JSON.stringify(role);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-role', params, { headers: headers }).toPromise();
    }

    // GET ALL ROLE ACCESS BY ROLE
    getOneRoleAccess(role): Promise<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + 'erprest-roleAccess/one/' + role, { headers: headers }).toPromise();       
        // return this._http.get(this.url + 'erprest-roleAccess' + role, { headers: headers }).toPromise();
    }   

    // INSERT ROLE - ACCESS
    insertRoleAccess(roleAccess: RoleAccess): Promise<any> {
        let params = JSON.stringify(roleAccess);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'erprest-roleAccess', params, { headers: headers }).toPromise();
    }

    // UPDATE ROLE - ACCESS
    updateRoleAccess(roleAccess: RoleAccess): Promise<any> {
        let params = JSON.stringify(roleAccess);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'erprest-roleAccess', params, { headers: headers }).toPromise();
    }
}